import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TestCaricoService } from '../testDiCaricoService/test-carico.service';


@Component({
  selector: 'app-spy-template',
  templateUrl: './spy-template.component.html',
  styleUrls: ['./spy-template.component.scss']
})
export class SpyTemplateComponent implements OnInit {

  modalRef: BsModalRef;
  isRecording = false
  timeMod: number = 1

  speedMods: number[] = [0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 5, 10]

  constructor(
    private modalService: BsModalService,
    private recordingService: TestCaricoService
  ) { }


  ngOnInit() {
  }


  openModal(template: TemplateRef<any>) {


    if (!this.isRecording)
      this.modalRef = this.modalService.show(template);
    else {
      this.isRecording = false
      this.download(this.recordingService.getJsonReced())
      this.recordingService.startAndStopRecording(false, this.timeMod)


    }
  }

  changeRegistra(isRecording: boolean) {


    this.isRecording = isRecording

    this.recordingService.startAndStopRecording(isRecording, this.timeMod)



    this.modalRef.hide()
  }



  download(fileTo) {
    let file = new Blob([JSON.stringify(fileTo)]);
    let a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = "testDiCarico.json";
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }


  setSpeed(speed) {
    this.timeMod = speed
  }


}
