import { Injectable } from '@angular/core';
import { links } from '../../ipRequestConfig';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DTOUser } from '../Util/DTO/DTOUser';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { DomandaIstruttoria } from '../Util/DTO/DTOIstruttoria/DomandaIstruttoria';
import { DataService } from './data.service';
import { DomandaIstruttoriaRendicontazione } from '../Util/DTO/DTOIstruttoriaRendicontazione/DomandaIstruttoriaRendicontazione';
import { DomandaIstrRendFasi } from '../Util/DTO/DTOIstruttoriaRendicontazione/DomandaIstrRendFasi';
export const mainLink = links


@Injectable({
  providedIn: 'root'
})


export class RequestService {


  constructor(private router: HttpClient, private innerRouter: Router, private data: DataService) {

  }

  loginWithSpid() {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),

      withCredentials: true,

    };
    return this.router.post<any>(mainLink.address + mainLink.loginWithSpid, {}, httpOptions)
  }


  loginRequest(user: string, stringPassword: string) {
    let condi = this.data.getCookie() != null ? this.data.getCookie() : false
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),

      withCredentials: true,

    };
    let reqOBJ = {
      email: user,
      password: stringPassword,
      checkCookie: condi
    }
    return this.router.post<any>(mainLink.address + mainLink.login, reqOBJ, httpOptions)
  }

  impersonifica(codiceFiscale: string) {
    let condi = this.data.getCookie() != null ? this.data.getCookie() : false
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    let reqOBJ = {
      email: codiceFiscale,
      checkCookie: condi
    }
    return this.router.post<any>(mainLink.address + mainLink.impersonifica, reqOBJ, httpOptions)
  }

  recuperaPasswordRequest(userToBe: DTOUser) {
    let SaveUserDTO = {
      user: userToBe,
    }
    return this.router.post<any>(mainLink.address + mainLink.recuperaPassword, SaveUserDTO)
  }
  spidRequest() {
    let link = "?entityId=https://iam.regione.lazio.it/nidp/saml2/metadata"
    return this.router.get<any>(mainLink.address + mainLink.spid + link, {})
  }
  infocamera(codiceFiscaleInfocamera, idU, whitelist) {
    let pIva = codiceFiscaleInfocamera
    let reqOBJ = {
      risposta: pIva,
      idUtente: idU,
      wlToFind: whitelist
    }
    return this.router.post<any>(mainLink.address + mainLink.infocamera, reqOBJ)
  }



  /**
   *
   * @param idUtente idUtente per recuperare le domande associate
   *                 (non ha effetto sulla ricerca dei bandi)
   */
  askDomandeEBandi(idUtente: number, idBan: number, onlyb: boolean) {
    return this.router.post<any>(mainLink.address + mainLink.askDomandEBandi, {
      idUser: idUtente,
      onlyBandi: onlyb,
      idBando: idBan,
      domandeList: [],
      bandiList: [],
      ricercaDomande: false
    })

  }

  /**
   *
   * @param idUtente idUtente che effettua la chiamata
   * @param filtri proprietà per filtrare le domande
   */
  askDomandeEBandiWithFiltri(filtri: any) {
    filtri["ricercaDomande"] = true
    return this.router.post<any>(mainLink.address + mainLink.askDomandEBandi, filtri)

  }
  askSingolaDomanda(idDomandaDaCercare) {

    return this.router.post<any>(mainLink.address + mainLink.askSingoleDomanda, { idDomanda: idDomandaDaCercare })

  }

  saveuserRequest(userToSave: DTOUser) {
    let SaveUserDTO = {
      user: userToSave,
    }
    return this.router.post<any>(mainLink.address + mainLink.saveuser, SaveUserDTO)
  }

  saveUserSpid(userToSave: DTOUser) {
    let SaveUserDTO = {
      user: userToSave,
    }
    return this.router.post<any>(mainLink.address + mainLink.saveUserSpid, SaveUserDTO)
  }


  saveDomanda(domandaDaSalvare: any) {

    return this.router.post<any>(mainLink.address + mainLink.saveDomanda, domandaDaSalvare)
  }

  saveAggregazione(aggregazioneDaSalvare: any) {

    return this.router.post<any>(mainLink.address + mainLink.salvaAggregazione, aggregazioneDaSalvare)
  }



  //chiamate per le anagrafiche
  salvaNuovoEnte(enteDaSalvare: any) {

    return this.router.post<any>(mainLink.address + mainLink.saveEnte, enteDaSalvare)
  }
  salvaNuovoLiberoProfessionista(anagDaSalvare: any) {
    return this.router.post<any>(mainLink.address + mainLink.savePersonaFisica, anagDaSalvare)
  }

  salvaCustomBoxPerSoggettoRichiedenteCapofila(objDiRaccordo) {
    return this.router.post<any>(mainLink.address + mainLink.saveCustomBoxCapoFila, objDiRaccordo)
  }


  askAllEntiDes() {
    return this.router.post<any>(mainLink.address + mainLink.getListaEnti, {})
  }

  askDettaglioEnte(idEnte: number) {
    return this.router.post<any>(mainLink.address + mainLink.getDettagliEnte, { id: idEnte })
  }


  askDettaglioInserimentoAnagraficaEnte() {
    return this.router.post<any>(mainLink.address + mainLink.getDettagliAnagraficaEnte, {})
  }

  askDettaglioInserimentoAnagrafica() {
    return this.router.post<any>(mainLink.address + mainLink.getDettagliAnagraficaEnte, {})
  }

  saveImpresa(objToSend: any) {
    return this.router.post<any>(mainLink.address + mainLink.saveImpresa, objToSend)
  }

  saveNoteImpresa(objToSend: any) {
    return this.router.post<any>(mainLink.address + mainLink.saveNoteImpresa, objToSend)
  }

  kickOut() {
    this.innerRouter.navigate(['login']);
  }


  downloadAllegato(objToSend: any) {
    return this.router.post<any>(mainLink.address + mainLink.downloadAllegato, objToSend)
  }
  domandaDownloadAllegato(objToSend: any) {
    return this.router.post<any>(mainLink.address + mainLink.domandaDownloadAllegato, objToSend)
  }

  saveAllegato(objToSend: any) {
    return this.router.post<any>(mainLink.address + mainLink.saveDomandaAllegato, objToSend)
  }

  salvaConfigurazione(objToSend: any) {
    return this.router.post<any>(mainLink.address + mainLink.salvaConfigurazione, objToSend)
  }

  recuperaConfigurazione(objToSend: any) {
    return this.router.post<any>(mainLink.address + mainLink.recuperaConfigurazione, objToSend)
  }

  eliminaDomanda(objToSend: any) {
    return this.router.post<any>(mainLink.address + mainLink.eliminaDomanda, objToSend)

  }


  /**
   * recupera le regole di misura dal vecchio BE, dato l'id del bando associato
   */
  getRegoleDiMisura(idBando: number, idDomanda: any) {

    return this.router.post<any>(mainLink.address + mainLink.getRegoleDiMisura, { idBando: idBando, id: idDomanda })


  }
  /**
     *
     * @param idDomanda id della domanda
     * @param nomeFile nome del file senza estenzione
     * @param estenzione estenzione del file
     */
  downloadAllegatoVociDiCosto(idDomanda: number, nomeFile: string, estenzione: string, timeStamp: string, idVoceCosto, isVoceDiSpesa: boolean) {

    let reqBody = {
      id: idDomanda,
      titolo: timeStamp + "-" + nomeFile,
      fileExt: estenzione,
      idAllegatoBando: idVoceCosto,
      forVociDiSpesa: isVoceDiSpesa
    }


    return this.router.post<any>(mainLink.address + mainLink.downloadAllegatoVociDiCosto, reqBody)
  }




  recuperaUtility() {
    return this.router.post<any>(mainLink.address + mainLink.recuperaUtility, {})
  }


  recuperaVociDiCosto(idDomanda: any) {
    return this.router.post<any>(mainLink.address + mainLink.salvaVociDiCosto, idDomanda)

  }

  salvaVociDiCosto(vociDaSalvare: any[]) {
    return this.router.post<any>(mainLink.address + mainLink.salvaVociDiCosto, vociDaSalvare)
  }

  verificaAnag(domanda: any) {
    return this.router.post<any>(mainLink.address + mainLink.verificaAnag, domanda)
  }

  verificaPiva(anagImpresaIva: any) {
    return this.router.post<any>(mainLink.address + mainLink.verificaPiva, anagImpresaIva)
  }

  verificaAti(domanda: any) {
    return this.router.post<any>(mainLink.address + mainLink.verificaAti, domanda)
  }

  verificaProgetto(domanda: any) {
    return this.router.post<any>(mainLink.address + mainLink.verificaProgetto, domanda)
  }

  verificaAllegati(domanda: any) {
    return this.router.post<any>(mainLink.address + mainLink.verificaAllegati, domanda)
  }
  verificaCosti(domanda: any) {
    return this.router.post<any>(mainLink.address + mainLink.verificaCosti, domanda)
  }
  verificaAllegatiFinalizzazione(domanda: any) {
    return this.router.post<any>(mainLink.address + mainLink.verificaAllegatiFinalizzazione, domanda)
  }

  verificaFinale(domanda: any) {
    return this.router.post<any>(mainLink.address + mainLink.verificaFinale, domanda)
  }

  verificaVariazione(domanda: any) {
    return this.router.post<any>(mainLink.address + mainLink.verificaVariazione, domanda)
  }

  finalizzaconactiviti(domanda: any) {
    return this.router.post<any>(mainLink.address + mainLink.finalizzaconactiviti, domanda)
  }

  getHomePageDomandeIstruttore(DomandeIstruttoreDTO: any) {
    return this.router.post<any>(mainLink.address + mainLink.getHomePageDomandeIstruttore, DomandeIstruttoreDTO)
  }
  salvaPm(DomandeIstruttoreDTO: any) {
    return this.router.post<any>(mainLink.address + mainLink.salvaPm, DomandeIstruttoreDTO)
  }

  soccorsoIstruttoria(DomandeIstruttoreDTO: any) {
    return this.router.post<any>(mainLink.address + mainLink.soccorsoIstruttoria, DomandeIstruttoreDTO)
  }

  salvaIstruttoria(istruttoria: DomandaIstruttoria) {
    return this.router.post<any>(mainLink.address + mainLink.saveIstruttoria, istruttoria)
  }

  soccorsoCompilatore(domanda: any) {
    return this.router.post<any>(mainLink.address + mainLink.soccorsoCompilatore, domanda)
  }
  chiudiSoccorsoCompilatore(allegati: any) {
    return this.router.post<any>(mainLink.address + mainLink.chiudiSoccorsoCompilatore, allegati)
  }

  //verifica per le voci di costo in istruttoria
  verificaIstruttoria(idDomanda: number) {
    return this.router.post<any>(mainLink.address + mainLink.verificaIstruttoria, idDomanda)

  }

  /**
   *
   * salva un esito istruttoria e la chiude
   *
   * @param esitoDaSalvare
   */
  salvaEsitoIstruttoria(esitoDaSalvare: any) {
    return this.router.post<any>(mainLink.address + mainLink.salvaEsitoIstrutotria, esitoDaSalvare)
  }
  salvaEsitoIstruttoriaInBozza(esitoDaSalvare: any) {
    return this.router.post<any>(mainLink.address + mainLink.salvaEsitoIstruttoriaInBozza, esitoDaSalvare)
  }

  salvaEsitoIstruttoriaAllegati(objToSend: any) {
    return this.router.post<any>(mainLink.address + mainLink.salvaEsitoIstruttoriaAllegati, objToSend)
  }

  recuperaEsitoIstruttoria(idEsitoIstruttoria) {
    return this.router.post<any>(mainLink.address + mainLink.recuperaEsitoIstruttoria, idEsitoIstruttoria)
  }

  recuperaFasiIstruttorieConEsito(objToSend: any){
    return this.router.post<any>(mainLink.address + mainLink.recuperaFasiIstruttorieConEsito, objToSend);
  }

  recuperaStoricoDetermina(objToSend: any){
    return this.router.post<any>(mainLink.address + mainLink.recuperaStoricoDetermina, objToSend);
  }

  recuperaEsitiFasiConcluse(idDomanda) {
    return this.router.post<any>(mainLink.address + mainLink.recuperaEsitiFasiConcluse, idDomanda)
  }

  chiudiIstruttoriaNDV(esitoNDV) {
    return this.router.post<any>(mainLink.address + mainLink.finalizzaIstruttoriaNDV, esitoNDV)
  }

  salvaEsitoIstruttoriaNdVInBozza(esitoNDV) {
    return this.router.post<any>(mainLink.address + mainLink.salvaEsitoIstruttoriaNdVInBozza, esitoNDV)
  }

  getBeVerison() {
    return this.router.post<any>(mainLink.address + mainLink.getBEVersion, {})
  }

  recuperaFaseIstruttore(codiceBando: any) {
    return this.router.post<any>(mainLink.address + mainLink.recuperaFaseIstruttore, codiceBando)
  }

  getGraduatoria(idB: number) {
    let objToSend = { idBando: idB }
    return this.router.post<any>(mainLink.address + mainLink.getGraduatoria, objToSend)
  }


  saveGraduatoria(domandegrad: any) {

    return this.router.post<any>(mainLink.address + mainLink.saveGraduatoria, domandegrad)
  }

  getDetermina(obj: any) {

    return this.router.post<any>(mainLink.address + mainLink.getDetermina, obj)
  }

  saveDetermina(obj: any) {

    return this.router.post<any>(mainLink.address + mainLink.saveDetermina, obj)
  }

  downloadDeterminaAllegato(obj: any) {

    return this.router.post<any>(mainLink.address + mainLink.downloadDeterminaAllegato, obj)
  }
  eliminaDetermina(obj: any) {

    return this.router.post<any>(mainLink.address + mainLink.eliminaDetermina, obj)
  }


  recuperaSpeseForfettarieConfigurate(idBando: number) {

    return this.router.post<any>(mainLink.address +
      mainLink.recuperaSpeseForfettarieConfigurate, idBando)

  }

  cancellaCodiceSegretoDomanda(idDomanda: number, iReallyNeedToDoThat): Promise<boolean> {



    return new Promise<boolean>((resolve) => {
      if (iReallyNeedToDoThat && (idDomanda != null && !isNaN(idDomanda)))
        this.router.post<any>(mainLink.address + mainLink.cancellaCodiceSegreto, idDomanda).subscribe(risposta => {
          resolve(risposta);
        });
      else
        resolve(true);


    });


  }



  downloadDocumentoFinalizzazione(idBando: number, idDomanda: number, idTipoCapofila: number) {

    return this.router.post<any>(mainLink.address +
      mainLink.downloadDocumentoFinalizzazione, {
      idDomanda: idDomanda,
      idBando: idBando,
      idTipo: idTipoCapofila
    })

  }

  downloadInformativaDatiPersonali() {
    return this.router.get<any>(mainLink.address + mainLink.getInformativaDatiPersonali, {});
  }


  getAtto(obj: any) {

    return this.router.post<any>(mainLink.address + mainLink.getAtto, obj)
  }

  getAttoAvanzamento(obj: any) {

    return this.router.post<any>(mainLink.address + mainLink.getAttoAvanzamento, obj)
  }

  saveAtto(obj: any) {

    return this.router.post<any>(mainLink.address + mainLink.saveAtto, obj)
  }
  downlaodAtto(obj: any) {

    return this.router.post<any>(mainLink.address + mainLink.downloadAttollegato, obj)
  }

  getHomePageRendicontazionePM(DomandeIstruttoreDTO: any) {
    return this.router.post<any>(mainLink.address + mainLink.getHomePageRendicontazionePM, DomandeIstruttoreDTO)
  }

  saveAnticipo(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.saveAnticipo, obj)
  }

  getAnticipo(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.getAnticipo, obj)
  }


  assegnaRendicontazione(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.assegnaRendicontazione, obj)
  }

  getHomapageRendicontazione(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.getHomapageRendicontazione, obj)
  }
  getFaseCompilatore(idFaseRend: number) {
    let obj = { id: idFaseRend }
    return this.router.post<any>(mainLink.address + mainLink.getFaseCompilatore, obj)
  }


  salvaVociDiSpesa(obj: any) {

    return this.router.post<any>(mainLink.address + mainLink.saveVociDiSpesa, obj)

  }

  salvaIstruttoriaRendicontazione(istruttoriaRendicontazione: DomandaIstruttoriaRendicontazione) {
    return this.router.post<any>(mainLink.address + mainLink.saveIstruttoriaRendicontazione, istruttoriaRendicontazione);
  }

  getIstruttoriaRendicontazione(idDomanda: any) {

    return this.router.post<any>(mainLink.address + mainLink.getIstruttoriaRendicontazione, idDomanda);
  }

  getFasePrecIstruttoriaRendicontazione(idDomanda: any) {
    return this.router.post<any>(mainLink.address + mainLink.getFasiPrecedentiIstruttoriaRendicontazione, idDomanda);
  }

  saveAllegatiRendicontazione(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.saveAllegatiRendicontazione, obj);
  }

  downloadAllegatoRendicontazione(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.downloadAllegatoRendicontazione, obj);
  }
  soccorsoRendicontazioneIstruttore(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.soccorsoRendicontazioneIstruttore, obj);
  }
  soccorsoRendicontazioneCompilatore(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.soccorsoRendicontazioneCompilatore, obj);
  }
  chiudiSoccorsoRendicontazione(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.chiudiSoccorsoRendicontazione, obj);
  }

  getHomePageErogazionePm(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.getHomePageErogazionePm, obj);
  }
  assegnaErogazione(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.assegnaErogazione, obj);
  }
  getErogazione(obj: any){
    return this.router.post<any>(mainLink.address + mainLink.getErogazione, obj);
  }
  salvaErogazione(obj: any){
    return this.router.post<any>(mainLink.address + mainLink.salvaErogazione, obj);
  }
  getEsitoNdv(obj: any){
    return this.router.post<any>(mainLink.address + mainLink.getEsitoNdv, obj);
  }
  salvaFaseRendContributo(obj: any){
    return this.router.post<any>(mainLink.address + mainLink.salvaFaseRendContributo, obj);
  }
  getFaseRendicontazioneContributo(obj: any){
    return this.router.post<any>(mainLink.address + mainLink.getFaseRendicontazioneContributo, obj);
  }

  getFaseRendicontazioneCompilatore(obj: any){
    return this.router.post<any>(mainLink.address + mainLink.getFaseRendicontazioneCompilatore, obj);
  }

  //finalizza una fase istruttore rendicontazione
  finalizzaFaseIstruttoriaRendicontazione(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.finalizzaFaseIstruttoriaRendicontazione, obj);
  }


  salvaCorrezioniSpeseRend(objToSave: any[]) {
    return this.router.post<any>(mainLink.address + mainLink.salvaCorrezioniSpese, objToSave);

  }


  downloadDocumentoRichiestaRendicontazione(idBando: number, idDomanda: number, idTipoCapofila: number,idFase: number) {


    return this.router.post<any>(mainLink.address +
      mainLink.downloadDocumentoRichiestaRendicontazione, {
      idDomanda: idDomanda,
      idBando: idBando,
      idTipo: idTipoCapofila,
      idTipoFasi : idFase
    })

  }

  finalizzaRendicontazione(objToSave: any) {
    return this.router.post<any>(mainLink.address + mainLink.finalizzaRendicontazione, objToSave);

  }

  costituisciImpresa(objToSave: any) {
    return this.router.post<any>(mainLink.address + mainLink.costituisciImpresa, objToSave);

  }

  verificaAllegatiRendicontazione(objToSend:any){
    return this.router.post<any>(mainLink.address + mainLink.verificaAllegatiRendicontazione, objToSend);
  }
  verificaRendicontazione(objToSend:any){
    return this.router.post<any>(mainLink.address + mainLink.verificaRendicontazione, objToSend);
  }

  askSingolaDomandaPM(idDomandaDaCercare:number){
    return this.router.post<any>(mainLink.address + mainLink.askSingolaDomandaPM, { idDomanda: idDomandaDaCercare });
  }

  salvaRichieste(objToSend:any){
    return this.router.post<any>(mainLink.address + mainLink.salvaRichieste, objToSend);
  }
  getBandiPM(objToSend:any){
    return this.router.post<any>(mainLink.address + mainLink.getBandiPM, objToSend);
  }
  cercaDomandePerBando(objToSend:any){
    return this.router.post<any>(mainLink.address + mainLink.cercaDomandePerBando, objToSend);
  }

  startCampionamento(domandaId: any) {
    return this.router.post<any>(mainLink.address + mainLink.startCampionamento, domandaId)
  }

  richiediDurc(durc: any) {
    return this.router.post<any>(mainLink.address + mainLink.richiediDurc, durc)
  }

  downloadDocumentoCampionamento(idBando: number, idDomanda: number, idTipoCapofila: number,idFase: number,idAnag: number, tipoTab:number) {
console.log({
  idDomanda: idDomanda,
  idBando: idBando,
  idTipo: idTipoCapofila,
  idTipoFasi : idFase,
  idAnag : idAnag,
  tipoTab : tipoTab
})

    return this.router.post<any>(mainLink.address +
      mainLink.downloadDocumentoCampionamento, {
      idDomanda: idDomanda,
      idBando: idBando,
      idTipo: idTipoCapofila,
      idTipoFasi : idFase,
      idAnag : idAnag,
      tipoTab : tipoTab
    })

  }
  richiediCap(idB: any) {
    let obj = {idBando:idB}
    return this.router.post<any>(mainLink.address + mainLink.richiediCap, obj)
  }

  downloadModelloAutocomposto(idBando: number, idDomanda: number, idTipoCapofila: number,idFase: number) {


    return this.router.post<any>(mainLink.address +
      mainLink.downloadModelloAutocomposto, {
      idBando: idBando,
      idDomanda: idDomanda,
      idTipo: idTipoCapofila,
      idTipoFasi : idFase
    })

  }

  recuperaEsitoIstruttoriaAllegati(esitoIstruttoria) {
    return this.router.post<any>(mainLink.address + mainLink.recuperaEsitoIstruttoriaAllegati, esitoIstruttoria)
  }

  downloadEsitoIstruttoriaAllegati(objToSend: any) {
    return this.router.post<any>(mainLink.address + mainLink.downloadEsitoIstruttoriaAllegati, objToSend)
  }

  saveDateRange(objToSend: any) {
    return this.router.post<any>(mainLink.address + mainLink.saveDateRange, objToSend)
  }
  cercaBandiSa() {
    return this.router.post<any>(mainLink.address + mainLink.cercaBandiSa, {})
  }
  cercaDomandePerBandoSa(objToSend: any) {
    return this.router.post<any>(mainLink.address + mainLink.cercaDomandePerBandoSa, objToSend)
  }
  cercaFlussoSa(objToSend: any) {
    return this.router.post<any>(mainLink.address + mainLink.getHomePageRendicontazioneSA, objToSend)
  }
  getAttoSa(objToSend: any) {
    return this.router.post<any>(mainLink.address + mainLink.getAttoSA, objToSend)
  }
  getDeterminaSa(objToSend: any) {
    return this.router.post<any>(mainLink.address + mainLink.getDeterminaSa, objToSend)
  }
  getBandiV(objToSend:any){
    return this.router.post<any>(mainLink.address + mainLink.getBandiV, objToSend);
  }


  visualizzaDomandePerBando(objToSend:any){
    return this.router.post<any>(mainLink.address + mainLink.visualizzaDomandePerBando, objToSend);
  }

  getAliquota(objToSend: any) {
    return this.router.post<any>(mainLink.address + mainLink.getAliquota, objToSend)
  }

  saveIntegrazione(objToSend:any){
    return this.router.post<any>(mainLink.address + mainLink.saveIntegrazione, objToSend);
  }

  richiediCredenziali(objToSend:any){
    return this.router.post<any>(mainLink.address + mainLink.richiediCredenziali, objToSend);
  }


  getHomepageAutorizzazione(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.getHomepageAutorizzazione, obj);
  }
  autorizzazioneLR(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.autorizzazioneLR, obj);
  }
  autorizzaCompilatore(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.autorizzaCompilatore, obj);
  }
  accettaAuth(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.accettaAuth, obj);
  }

  verificaCompensative(domanda: any) {
    return this.router.post<any>(mainLink.address + mainLink.verificaCompensative, domanda)
  }

  saveVociDiSpesaAllegati(objToSend: any) {
    return this.router.post<any>(mainLink.address + mainLink.salvaVociDiSpesaAllegatiFromIntegrazione, objToSend);
  }

  generaPdfIntegrazioni(idIntegrazione: number) {
    return this.router.post<any>(mainLink.address + mainLink.generaPdfIntegrazioni, {idIntegrazione: idIntegrazione});
  }

  getVariazione(domanda: any) {
    return this.router.post<any>(mainLink.address + mainLink.getVariazione, domanda)
  }
  getVariazioneObj(domanda: any) {
    return this.router.post<any>(mainLink.address + mainLink.getVariazioneObj, domanda)
  }
  saveVariazione(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.saveVariazione, obj)
  }

  assegnaCompilatore(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.assegnaCompilatore, obj);
  }
  revocaCompilatore(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.revocaCompilatore, obj);
  }

  saveVariazioneObj(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.saveVariazioneObj, obj)
  }

  configAtto(idB: any,idDom:any) {
    let obj = {idBando:idB, idDomanda:idDom}
    return this.router.post<any>(mainLink.address + mainLink.configAtto, obj)
  }
  salvaConcessione(concessione: any) {
    return this.router.post<any>(mainLink.address + mainLink.salvaConcessione, concessione)
  }

  verificaConcessione(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.verificaConcessione, obj)
  }

  saveVariazioneInfocamere(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.saveVariazioneInfocamere, obj)
  }

  finalizzaVariazioni(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.finalizzaVariazioni, obj)
  }

  getSingoloAllegato(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.getSingoloAllegato, obj)
  }

  modificheVariazioni(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.modificheVariazioni, obj)
  }

  istruttoriaVariazione(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.istruttoriaVariazione, obj)
  }

  salvaIstruttoriaVariazione(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.salvaIstruttoriaVariazione, obj)
  }

  annullaVariazione(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.annullaVariazione, obj)
  }




  domandePerBando(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.domandePerBando, obj)
  }

  getAttoSaSingle(obj: any) {
    return this.router.post<any>(mainLink.address + mainLink.getAttoSaSingle, obj)
  }
}
