import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AnagrGuardService implements CanActivate {

  constructor(private cookieService: CookieService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    var kickOut
    if (this.cookieService.get('usL') != "") {
      kickOut =
        !(JSON.parse(this.cookieService.get("usL")).loggedIn)
    }
    else kickOut = true


    if (kickOut) {
      this.router.navigate(['login']);
      return false
    }



    return true
  }
}