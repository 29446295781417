import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { envSelector } from './environments/custom/environment.locale'
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


let localSelector: any = envSelector

if (environment.production || localSelector == 'produzione') {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  preserveWhitespaces: true
})
  .catch(err => console.error(err));
