class ProfiliUtenze{
    id: number
    descrizione:string

    constructor(){
        this.id=2
        this.descrizione=""
    }
}
export class DTOUser{

    id: number
    email:string
    codiceFiscale:string
    cellulare:string
    password:string
    username:string
    sesso:string
    nome:string
    cognome:string
    attivo:boolean
    luogoNascita:string
    informativaPrivacy:boolean
    newsletter:boolean
    dataNascita:string
    profilo: ProfiliUtenze
    eliminato:boolean
    primoAccesso:boolean
    passwordToMail:string

    constructor(){
        this.id=null
        this.email=""
        this.codiceFiscale=""
        this.cellulare=""
        this.password=""
        this.username=""
        this.nome=""
        this.cognome=""
        this.attivo=true
        this.informativaPrivacy=true
        this.newsletter=false
        this.eliminato=false
        this.primoAccesso=false
        this.profilo=new ProfiliUtenze()
        this.passwordToMail=""
    }

}


