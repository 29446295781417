import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrotondaNumero'
})
export class ArrotondaNumeroPipe implements PipeTransform {

  transform(numero: number): number {
    // Assicurati di avere almeno tre cifre decimali
    if(numero == null || isNaN(numero)){
      return 0
    }
    const numeroFormattato = numero.toFixed(3);

    // Moltiplica per 1000 per spostare il terzo decimale a sinistra
    const numeroMoltiplicato = parseFloat(numeroFormattato) * 1000;

    // Estrai il terzo decimale
    const terzoDecimale = Math.floor(numeroMoltiplicato) % 10;

    // Se il terzo decimale è maggiore o uguale a 5, arrotonda per eccesso, altrimenti arrotonda per difetto
    if (terzoDecimale >= 5) {
        return Math.ceil(numero * 100) / 100;
    } else if (terzoDecimale == 0) {
        return numero;
    } else {
        return Math.floor(numero * 100) / 100;
    }
  }

}
