import { profile } from '../../../environments/custom/environmentProfiles/generalProfie';
import { envSelector } from '../../../environments/custom/environment.locale';
import * as _ from 'lodash';


export abstract class Printer {

  //variabili per gli ambienti,
  //queste rappresentano se un'operazione è permessa o no
  locale: boolean = true;
  sviluppo: boolean = false;
  produzione: boolean = false;
  collaudo: boolean = false;

  // se è true bisogna stampare in formato Json
  isJson: boolean = false;
  //per distingere se il front end è stato avviato con la propria classe
  isAllowedForMe: boolean = false;

  //per disabilitare velocemente tutti i successivi print
  disabled: boolean = false;

  //dei printer ad oc, a seconda dello sviluppo attuale
  //è possibile abilitare/disabilitare velocemente tutti i possibili print
  flag: string = null
  flagTrancient: string = null



  constructor() {
    this.isAllowedForMe = this.getOwner() == profile.type;
  }

  abstract getOwner(): string;


  out(param): void {
    
    if (this.checkAll()) {
      if (this.isJson) {
        console.log(JSON.stringify(param));
      } else {
        console.log(param);
      }
    }
  }

  outAll(...params): void {
    if (this.checkAll()) {
      if (params == null) {
        console.log(null);
        return;
      }

      params.forEach(elementToPrint => {
        if (this.isJson) {
          console.log(JSON.stringify(elementToPrint));
        } else {
          console.log(elementToPrint);
        }
      });

    }
  }


  outForce(param): void {
    if (this.isJson) {
      console.log(JSON.stringify(param));
    } else {
      console.log(param);
    }
  }

  outAllForce(...params): void {

    if (params == null) {
      console.log(null);
      return;
    }

    params.forEach(elementToPrint => {
      if (this.isJson) {
        console.log(JSON.stringify(elementToPrint));
      } else {
        console.log(elementToPrint);
      }
    });

  }


  asJson(): Printer {
    let copy = this
    copy.isJson = true;
    return copy;
  }

  printSviluppo() {
    let copy = this
    copy.locale = false;
    copy.sviluppo = true;
    return copy;
  }

  printProduzione() {
    let copy = this
    copy.locale = false;
    copy.produzione = true;
    return copy;
  }

  printCollaudo() {
    let copy = this
    copy.locale = false;
    copy.collaudo = true;
    return copy;
  }

  printLocale() {
    let copy = this
    copy.locale = true;
    return copy;
  }


  disable() {
    this.disabled = true
  }

  enable() {
    this.disabled = false
  }




  setFlag(flagToSet) {
    this.flag = flagToSet
  }

  printFlag(flagToPrint) {
    let copy = this
    copy.flagTrancient = flagToPrint
    return copy
  }

  resetFlag() {
    this.flag = null
  }


  isMe(): boolean { //no, is not Mario tbh
    return this.isAllowedForMe
  }

  isLocale() {
    //  return envSelector == "locale"
  }
  isSviluppo() {
    //return envSelector == "sviluppo"
  }

  isProduzione() {
    // return envSelector == "produzione"
  }

  isCollaudo() {
    //  return envSelector == "collaudo"
  }

  //funzioni che restituiscono true se permettono di stampare

  private checkPermissionEnviroment(): boolean {
    let selector: string = envSelector
    let env1Sviluppo: string = "sviluppo"
    let env2Produzione: string = "produzione"
    let env3Locale: string = "locale"
    let env3Collaudo: string = "collaudo"

    return (this.sviluppo && (selector == env1Sviluppo)) ||
      (this.produzione && (selector == env2Produzione)) ||
      (this.collaudo && (selector == env3Collaudo)) ||
      (this.locale && (selector == env3Locale))
  }


  private checkPermissionUser(): boolean {
    return this.isAllowedForMe
  }

  private checkFlag() {
    if (this.flag == null || this.flag == this.flagTrancient)
      return true
  }

  private checkEnable(): boolean { return !this.disabled }

  checkAll(): boolean {
    return this.checkEnable() && this.checkFlag()
      && this.checkPermissionEnviroment() && this.checkPermissionUser()
  }


  /**
   * metodo di esempio, con alcuni utilizzi di questo tool.
   * non ha alcuna utilità pratica.
   */
  private metodoDiEsempio() {
    let printerLorenzo: Printer = this

    {/* ESEMPIO 1 */

      //stampa solo se il proggetto è stato avviato/compilato 
      //con il flag "-c lorenzo"  e senza specificare l'ambiente 
      //(con i flag -c sviluppo, -c collaudo ecc non verrà stampato). Per utilizzo solo in locale
      // stampa: Hello word!
      printerLorenzo.out("Hello word!");

    }


    {/* ESEMPIO 2 */
      //non importa chi l'ha avviato, i flag o l'ambiente, stamperà sempre
      //ciò che gli viene passato
      printerLorenzo.outForce("")

    }

    {/* ESEMPIO 3 */

      //stampa solo se il proggetto è stato avviato/compilato 
      //con i flag "-c collaudo" e "-c lorenzo".
      //questo metodo produce sette stampe, una per ogni elemento della collezione.
      //ogni elemento verà stampato in formato JSON
      printerLorenzo.asJson().printCollaudo().outAll([1, 2, 3, 4, 5, 6, 7]);

    }

    {

      //stampa solo se il proggetto è stato avviato/compilato con il flag "-c lorenzo"
      // e solo se si ha aggiunto anche il flag "-c sviluppo" oppure "-c produzione"
      printerLorenzo.printSviluppo().printProduzione().out("")
    }


  }



}



