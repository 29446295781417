import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: '404.component.html'
})
export class P404Component implements OnInit{
href:string

  constructor(private router: Router,private activatedRoute: ActivatedRoute) { }
ngOnInit(){
  console.log("test")
  this.activatedRoute.queryParams.subscribe(params => {
    let date = params['token'];
    if (date!= null)
    this.router.navigate(["/"])
    else
    this.router.navigate(["#/404"])
    console.log(date); // Print the parameter to the console. 
});
}
}


