import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
//import { RegisterComponent } from './views/register/register.component';
import { RegistrazioneComponent } from './pages/registrazione/registrazione.component';
import { AnagrGuardService } from './services/guard/guardAnagrafiche/anagr-guard.service';
import { RedirectComponent } from './views/redirect/redirect.component';
import { IstrAuthComponent } from './views/istr-auth/istr-auth.component';

export const routes: Routes = [

  {
    path: '',
    canActivate:[AnagrGuardService],
    redirectTo: 'gestioneDomande',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: 'redirect',
    component: RedirectComponent,
    data: {
      title: 'redirect'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'istr-auth',
    component: IstrAuthComponent,
    data: {
      title: 'Istruzioni Page'
    }
  },
  /*{
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },*/
  {
    path: 'registrazione',
    component: RegistrazioneComponent,
    data: {
      title: 'Registrazione'
    }
  },
  {
    path: '',
    canActivate:[AnagrGuardService],
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        canActivate: [AnagrGuardService],
        path: 'gestioneDomande',
        loadChildren: './pages/gestioneDomande/gestioneDomande.module#GestioneDomandeModule'
      },
      {
        canActivate: [AnagrGuardService],
        path: 'istruttoriaDomande',
        loadChildren: './pages/gestioneDomande/gestioneDomande.module#GestioneDomandeModule'
      },
      {
        canActivate: [AnagrGuardService],
        path: 'determina',
        loadChildren: './pages/determina/determina.module#DeterminaModule'
      },

      {
        canActivate: [AnagrGuardService],
        path: 'attoDimpegno',
        loadChildren: './pages/atto-dimpegno/atto-dimpegno.module#AttoDiImpegnoModule'
      },

      {
        canActivate: [AnagrGuardService],
        path: 'rendicontazione',
        loadChildren: './pages/rendicontazione/rendicontazione.module#RendicontazioneModule'
      },

      {
        canActivate: [AnagrGuardService],
        path: 'erogazione',
        loadChildren: './pages/erogazione-homepage/erogazione-homepage.module#ErogazioneHomepageModule'
      },


      {
        canActivate: [AnagrGuardService],
        path: 'flussoIstruttorio',
        loadChildren: './pages/gestioneDomande/flusso-istruttorio/flusso-istruttorio.module#FlussoIstruttorioModule'
      },

      {
        canActivate: [AnagrGuardService],
        path: 'istruttoriaGruppi',
        loadChildren: './pages/istruttoria-gruppi/istruttoria-gruppi.module#IstruttoriaGruppiModule'
      },

      {
        canActivate: [AnagrGuardService],
        path: 'abilitazione',
        loadChildren: './pages/abilitazione/abilitazione.module#AbilitazioneModule'
      },
      {
        canActivate: [AnagrGuardService],
        path: 'impersonifica',
        loadChildren: './pages/impersonifica/impersonifica.module#ImpersonificaModule'
      },
      {
        canActivate: [AnagrGuardService],
        path: 'variazioni',
        loadChildren: './pages/variazioni-istruttoria/variazioni-istruttoria.module#VariazioniIstruttoriaModule'
      },




      {
        path: 'base',
        loadChildren: './views/base/base.module#BaseModule'
      },
      {
        path: 'buttons',
        loadChildren: './views/buttons/buttons.module#ButtonsModule'
      },
      {
        path: 'charts',
        loadChildren: './views/chartjs/chartjs.module#ChartJSModule'
      },
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'icons',
        loadChildren: './views/icons/icons.module#IconsModule'
      },
      {
        path: 'notifications',
        loadChildren: './views/notifications/notifications.module#NotificationsModule'
      },
      {
        path: 'theme',
        loadChildren: './views/theme/theme.module#ThemeModule'
      },
      {
        path: 'widgets',
        loadChildren: './views/widgets/widgets.module#WidgetsModule'
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { useHash: true }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
