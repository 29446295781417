import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enviroment-test-selector',
  templateUrl: './enviroment-test-selector.component.html',
  styleUrls: ['./enviroment-test-selector.component.scss']
})


export class EnviromentTestSelectorComponent implements OnInit {

titleL: string = "GECOWEB"
  
  constructor() { }

  ngOnInit() {
  }

}
