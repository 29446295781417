export class DTOgeneralReqEncapsulator {
    idTipoUtenza: number
    token: string;
    oggetto: any
    idUser: number

    constructor(idTipoUtenza: number, oggetto: any, token: string, idUtente: number) {
        this.idTipoUtenza = idTipoUtenza;
        this.token = token
        this.oggetto = oggetto;
        this.idUser = idUtente
    }
}