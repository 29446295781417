import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor(private router: Router,private activatedRoute: ActivatedRoute) { }
ngOnInit(){
  console.log("test")
  this.activatedRoute.queryParams.subscribe(params => {
    let date = params['redirect'];
    if (date == '1')
    this.router.navigate(["/"])
    else
    this.router.navigate(["#/404"])
    console.log(date); // Print the parameter to the console. 
});
}
}


