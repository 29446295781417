import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {DatePipe, DecimalPipe} from '@angular/common';
import {PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {CookieService} from 'ngx-cookie-service';
import {ModalModule} from 'ngx-bootstrap/modal';
import {AppComponent} from './app.component';
// Import containers
import {DefaultLayoutComponent} from './containers';

import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import {LoginComponent} from './views/login/login.component';
//import { RegisterComponent } from './views/register/register.component';
import {RegistrazioneComponent} from './pages/registrazione/registrazione.component';
import {AppAsideModule, AppBreadcrumbModule, AppFooterModule, AppHeaderModule, AppSidebarModule,} from '@coreui/angular';
// Import routing module
import {AppRoutingModule} from './app.routing';
// Import 3rd party components
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {ChartsModule} from 'ng2-charts/ng2-charts';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {RegisterComponent} from './views/register/register.component';
import {InterTest} from './services/Interseptor/InterTest';
import {RequestService} from './services/request.service';
import {BsDatepickerModule, defineLocale, itLocale} from 'ngx-bootstrap';
import {DataService} from './services/data.service';
import {AgmCoreModule} from '@agm/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// RECOMMENDED
import {MaurizioPrinterService} from './services/printer/maurizio/maurizio-printer.service';
import {LorenzoPrinterService} from './services/printer/lorenzo/lorenzo-printer.service';
import {RiccardoPrinterService} from './services/printer/riccardo/riccardo-printer.service';
import * as $ from 'jquery';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { SpyTemplateComponent } from './services/testDiCarico/spy-template/spy-template.component';
import { RedirectComponent } from './views/redirect/redirect.component';
import { EnviromentTestSelectorComponent } from './services/generalOperationService/enviroment-test-selector/enviroment-test-selector.component';
import { RendicontazioneComponent } from './pages/rendicontazione/rendicontazione.component';
import { ErogazioneHomepageComponent } from './pages/erogazione-homepage/erogazione-homepage.component';
import {ExcelService} from './services/exportExcel/excel.service';
import { VociDiCostoRimodulazioneComponent } from './pages/exComponent/rimodulazione/voci-di-costo-rimodulazione/voci-di-costo-rimodulazione.component';
import { VociDiCostoRimodulazioneSoggettoComponent } from './pages/exComponent/rimodulazione/voci-di-costo-rimodulazione-soggetto/voci-di-costo-rimodulazione-soggetto.component';
import { VociDiCostoRimodulazioneDettaglioComponent } from './pages/exComponent/rimodulazione/voci-di-costo-rimodulazione-dettaglio/voci-di-costo-rimodulazione-dettaglio.component';
import { IstruttoriaGruppiComponent } from './pages/istruttoria-gruppi/istruttoria-gruppi.component';
import { AbilitazioneComponent } from './pages/abilitazione/abilitazione.component';
import { ImpersonificaComponent } from './pages/impersonifica/impersonifica.component';
import { IstrAuthComponent } from './views/istr-auth/istr-auth.component';
import { ModalContentComponent } from './services/shareTemplate/ModalContentComponent';
import { ArrotondaNumeroPipe } from './Util/PIPE/arrotonda-numero/arrotonda-numero.pipe';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

//import { SingoloSoggettoVoceCostoComponent } from './pages/exComponent/singolo-soggetto-voce-costo/singolo-soggetto-voce-costo.component';
//import { InserimentoAllegatiComponent } from './pages/exComponent/inserimento-allegati/inserimento-allegati.component';
//import { GeoLocComponent } from './pages/exComponent/geo-loc/geo-loc.component';


defineLocale('it', itLocale);

@NgModule({
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAUGjNIzNhjWl7ZGyzqu-DRIcEPz4gIZyI',
      libraries: ['places']
    }),

    BrowserModule,
    // CarouselModule.forRoot(),
    AppRoutingModule,
    AppAsideModule,
    FormsModule, ReactiveFormsModule,
    AppBreadcrumbModule.forRoot(),
    BrowserAnimationsModule,
    AppFooterModule,
    AppHeaderModule,
    HttpClientModule,
    AppSidebarModule,
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    ChartsModule,
    TypeaheadModule.forRoot()
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    RegistrazioneComponent,
    SpyTemplateComponent,
    RedirectComponent,
    EnviromentTestSelectorComponent,
    IstrAuthComponent,
    ModalContentComponent,
    ArrotondaNumeroPipe,


   




    //InserimentoDettaglioVociCostoComponent,
    // SingoloSoggettoVoceCostoComponent,


  ],
  entryComponents: [
    ModalContentComponent, 
  ],
  providers:
    [DecimalPipe, RequestService, DataService, DatePipe, CookieService, MaurizioPrinterService, LorenzoPrinterService, RiccardoPrinterService, ExcelService,ArrotondaNumeroPipe, {
      provide: HTTP_INTERCEPTORS,
      useClass: InterTest,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}

