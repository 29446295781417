import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-content',
  template: `
  <div class="modal-header">
  <h4>Elaborazione della richiesta in corso</h4>
</div>

<div class="modal-body">

  <div class="container-fluid">


    <div class="row justify-content-center">
      <h5>Caricamento</h5>
    </div>
    <div class="row justify-content-center">

      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-success" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-danger" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-warning" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-light" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-dark" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
  `
})
export class ModalContentComponent {}
