import { baseUrl } from './environments/custom/environment.locale'


//"http://10.166.31.195:8086/service/"    //NTT DATA
//"http://gecoppweb.ddns.net/service/"    //Pubblico
//"http://10.129.2.92:8082/service/"      //Lazio Innova
//"http://localhost:8081/"                //Test Locale

// refactor per evitare i conflitti in ip config durante lo sviluppo.
// adesso l'assegnazione dei diversi link non è più manuale (commentare e scommentare l'indirizzo per l'ambiente appropriato)
// ma tramite diversi flag in fase di buil o serve il sistema prenderà in automatico i diversi link per i relativi ambienti

//locale: nessun flag
//sviluppo:  ng build --configuration=sviluppo
//produzione: ng build --configuration=produzione

//stesso metodo per ng serve

let enviroment: string = baseUrl




//prefissi per selezionare il livello di sicurezza

let protectedPath: string = "protected/"
let unProtectedPath: string = "open/"
let unProtectedGetPath: string = "open/get/"

//nome della categoria di servizi
let domandeService: string = "domandeService/"

let systemService: string = "sistemService/"
//nome della categoria di servizi
let anagraficheService: string = "anagService/"
let verificheService: string = "verificheService/"
let campionamentoService: string = "campionamentoService/"
let autorizzazioneService: string = "autorizzazioneService/"
let variazioniService: string = "variazioniService/"
let reportService: string = "reportService/"

export const links = {

  address: enviroment,
  login: unProtectedPath + "login/",
  impersonifica: unProtectedPath + "impersonifica/",
  spid: unProtectedGetPath + "auth-spid/",
  loginWithSpid: unProtectedPath + "spid/loginSpid/",
  saveUserSpid: unProtectedPath + "saveUserSpid/",
  //chiamate per la gestione delle utenze
  saveuser: unProtectedPath + "saveUser/",
  richiediCredenziali:unProtectedPath + "richiediCredenziali/",
  recuperaPassword: unProtectedPath + "recuperaPassword/",
  testInfocameraUnprotected: unProtectedPath + "testInfocameraUnprotected/",
  getInformativaDatiPersonali: unProtectedPath + "getInformativaDatiPersonali/",


  //chiamate per la gestione delle domande
  askDomandEBandi: protectedPath + domandeService + "getHomePageDomandeBandi/",
  askDomandEBandiTest: unProtectedPath + "getHomePageDomandeBandiTest/",
  saveDomanda: protectedPath + domandeService + "salvaDomanda/",
  askSingoleDomanda: protectedPath + domandeService + "cercaDomandaSingola/",
  salvaAggregazione: protectedPath + domandeService + "salvaAggregazione/",
  saveCustomBoxCapoFila: protectedPath + domandeService + "salvaCustomBoxSoggettoRichiedente/",
  downloadAllegato: protectedPath + domandeService + "downloadAllegato/",
  saveDomandaAllegato: protectedPath + domandeService + "/saveDomandaAllegato",
  getRegoleDiMisura: protectedPath + domandeService + "getRegoleDiMisura/",
  domandaDownloadAllegato: protectedPath + domandeService + "downloadDomandaAllegato/",
  salvaConfigurazione: protectedPath + domandeService + "salvaConfigurazione/",
  recuperaConfigurazione: protectedPath + domandeService + "recuperaConfigurazione/",
  eliminaDomanda: protectedPath + domandeService + "eliminaDomanda/",
  salvaVociDiCosto: protectedPath + domandeService + "salvaVociDiCosto",
  recuperaVociDiCosto: protectedPath + domandeService + "recuperaVociDiCosto/",
  downloadAllegatoVociDiCosto: protectedPath + domandeService + "downloadAllegatoVociDiCosto/",
  chiudiSoccorsoCompilatore: protectedPath + domandeService + "chiudiSoccorsoCompilatore/",
  getHomePageDomandeIstruttore: protectedPath + domandeService + "getHomePageDomandeIstruttore/",
  salvaPm: protectedPath + domandeService + "salvaPm/",
  soccorsoIstruttoria: protectedPath + domandeService + "soccorsoIstruttoria/",
  recuperaUtility: protectedPath + domandeService + "getConfig/",
  saveIstruttoria: protectedPath + domandeService + "saveIstruttoria/",
  soccorsoCompilatore: protectedPath + domandeService + "soccorsoCompilatore/",
  salvaEsitoIstrutotria: protectedPath + domandeService + "salvaChiudiIstruttoria/",
  salvaEsitoIstruttoriaInBozza: protectedPath + domandeService + "salvaEsitoIstruttoriaInBozza/",
  salvaEsitoIstruttoriaAllegati: protectedPath + domandeService + "salvaEsitoIstruttoriaAllegati/",
  recuperaEsitoIstruttoria: protectedPath + domandeService + "recuperaEsitoIstruttoria/",
  recuperaFasiIstruttorieConEsito: protectedPath + domandeService + "recuperaFasiIstruttorieConEsito/",
  recuperaStoricoDetermina: protectedPath + domandeService + "getStoricoDetermine/",
  recuperaEsitiFasiConcluse: protectedPath + domandeService + "recuperaEsitiFasiConcluse/",
  recuperaFaseIstruttore: protectedPath + domandeService + "recuperaFasiPerIstruttore/",
  getGraduatoria: protectedPath + domandeService + "getGraduatoria/",
  cancellaCodiceSegreto: protectedPath + domandeService + "cancellaCodiceSegretoDomanda/",
  downloadDocumentoFinalizzazione: protectedPath + domandeService + "downloadDocumentoFinalizzazione/",
  getAtto: protectedPath + domandeService + "getAttoDImpegno/",
  getAttoAvanzamento: protectedPath + domandeService + "getAttoDImpegnoAvanzamento/",
  saveAtto: protectedPath + domandeService + "saveAtto/",
  downloadAttollegato: protectedPath + domandeService + "downloadAttollegato/",
  recuperaSpeseForfettarieConfigurate: protectedPath + domandeService + "recuperaSpeseForfettarieConfigurate/",
  saveGraduatoria: protectedPath + domandeService + "saveGraduatoria/",
  getHomePageRendicontazionePM: protectedPath + domandeService + "getHomePageRendicontazionePM/",
  assegnaRendicontazione: protectedPath + domandeService + "assegnaRendicontazione/",
  getHomapageRendicontazione:protectedPath + domandeService + "getHomapageRendicontazione/",
  downloadAllegatoRendicontazione:protectedPath + domandeService + "downloadAllegatoRendicontazione/",
  soccorsoRendicontazioneIstruttore:protectedPath + domandeService + "soccorsoRendicontazioneIstruttore/",
  soccorsoRendicontazioneCompilatore:protectedPath + domandeService + "soccorsoRendicontazioneCompilatore/",
  saveAllegatiRendicontazione: protectedPath + domandeService + "saveAllegatiRendicontazione/",
  chiudiSoccorsoRendicontazione: protectedPath + domandeService + "chiudiSoccorsoRendicontazione/",
  costituisciImpresa: protectedPath + domandeService + "costituisciImpresa/",
  askSingolaDomandaPM: protectedPath + domandeService +"askSingolaDomandaPM",
  richiediCap: protectedPath + domandeService +"richiediCap",
  recuperaEsitoIstruttoriaAllegati: protectedPath + domandeService + "recuperaEsitoIstruttoriaAllegati/",
  downloadEsitoIstruttoriaAllegati: protectedPath + domandeService + "downloadEsitoIstruttoriaAllegati/",
  configAtto: protectedPath + domandeService +"configAtto",
  salvaConcessione: protectedPath + domandeService +"salvaConcessione",

  downloadModelloAutocomposto:protectedPath + domandeService +"downloadModelloAutocomposto",

  getDetermina: protectedPath + domandeService + "getDetermina/",
  saveDetermina: protectedPath + domandeService + "saveDetermina/",
  eliminaDetermina: protectedPath + domandeService + "eliminaDetermina/",
  downloadDeterminaAllegato: protectedPath + domandeService + "downloadDeterminaAllegato/",
  cercaBandiSa: protectedPath + domandeService + "cercaBandiSa/",
  cercaDomandePerBandoSa: protectedPath + domandeService + "cercaDomandePerBandoSa/",
  getHomePageRendicontazioneSA: protectedPath + domandeService + "getHomePageRendicontazioneSA/",
  getAttoSA: protectedPath + domandeService + "getAttoDImpegnoSa/",
  getDeterminaSa: protectedPath + domandeService + "getDeterminaSa/",
  getAliquota: protectedPath + domandeService + "getAliquota/",

  getAttoSaSingle: protectedPath + domandeService + "getAttoSaSingle/",
  //chiamate per la rendicontazione
  saveAnticipo: protectedPath + domandeService + "saveAnticipo/",
  getAnticipo: protectedPath + domandeService + "getAnticipo/",
  getFaseCompilatore: protectedPath + domandeService + "getFaseCompilatore/",
  saveVociDiSpesa: protectedPath + domandeService + "salvaVociDiSPesa/",
  saveIstruttoriaRendicontazione: protectedPath + domandeService + "saveIstruttoriaRendicontazione/",
  getIstruttoriaRendicontazione: protectedPath + domandeService + "getIstruttoriaRendicontazione/",
  getFasiPrecedentiIstruttoriaRendicontazione: protectedPath + domandeService + "getFasiPrecedentiIstruttoriaRendicontazione/",
  finalizzaFaseIstruttoriaRendicontazione:protectedPath + domandeService + "finalizzaFaseRendicontazioneIstruttore/",
  salvaCorrezioniSpese: protectedPath + domandeService + "salvaCorrezioniSpese/",
  downloadDocumentoRichiestaRendicontazione:protectedPath + domandeService +"downloadDocumentoRichiestaRendicontazione/",
  finalizzaRendicontazione:protectedPath + verificheService +"finalizzaRendicontazione/",
  downloadDocumentoCampionamento:protectedPath + domandeService +"downloadDocumentoCampionamento/",
  saveDateRange:protectedPath + domandeService +"saveDateRange/",


  saveIntegrazione: protectedPath + domandeService + 'saveIntegrazione/',
  salvaVociDiSpesaAllegatiFromIntegrazione: protectedPath + domandeService + 'salvaVociDiSpesaAllegatiFromIntegrazione/',
  generaPdfIntegrazioni: protectedPath + domandeService + 'generaPdfIntegrazioni/',

  //chiamate per l'erogazione
  getHomePageErogazionePm: protectedPath + domandeService + "getHomePageErogazionePm/",
  assegnaErogazione: protectedPath + domandeService + "assegnaErogazione/",
  getErogazione: protectedPath + domandeService + "getErogazione/",
  salvaErogazione: protectedPath + domandeService + "salvaErogazione/",
  getEsitoNdv: protectedPath + domandeService + "getEsitoNdv/",
  salvaFaseRendContributo: protectedPath + domandeService + "salvaFaseRendContributo/",
  getFaseRendicontazioneContributo: protectedPath + domandeService + "getFaseRendicontazioneContributo/",
  getFaseRendicontazioneCompilatore: protectedPath + domandeService + "getFaseRendicontazioneCompilatore/",

    //chiamate Gruppi
  getBandiV: protectedPath + domandeService + "getBandiV/",
  visualizzaDomandePerBando: protectedPath + domandeService + "visualizzaDomandePerBando/",


  //chiamate per la gestione delle anagrafiche
  getListaEnti: protectedPath + anagraficheService + "/getEnti",
  saveEnte: protectedPath + anagraficheService + "/salvaEnte",
  getDettagliEnte: protectedPath + anagraficheService + "/getDettagliEnte",
  getDettagliAnagraficaEnte: protectedPath + anagraficheService + "/getAnagraficaEnteDettails",
  savePersonaFisica: protectedPath + anagraficheService + "/saveLiberoProf",
  saveImpresa: protectedPath + anagraficheService + "/saveImpresa",
  saveNoteImpresa: protectedPath + anagraficheService + "/saveNoteImpresa",


  //chiamate pSystem
  infocamera: protectedPath + systemService + "infocamera/",

  //chiamate verifica
  verificaAnag: protectedPath + verificheService + "verificaCapofila/",
  verificaAti: protectedPath + verificheService + "verificaAti/",
  verificaProgetto: protectedPath + verificheService + "verificaProgetto/",
  verificaAllegati: protectedPath + verificheService + "verificaAllegati/",
  verificaCosti: protectedPath + verificheService + "verificaCosti/",
  verificaAllegatiFinalizzazione: protectedPath + verificheService + "verificaAllegatiFinalizzazione/",
  verificaFinale: protectedPath + verificheService + "finalizzazione/",
  finalizzaconactiviti: protectedPath + verificheService + "finalizzaconactiviti/",
  verificaIstruttoria: protectedPath + verificheService + "verificaIstruttoria/",
  finalizzaIstruttoriaNDV: protectedPath + domandeService + "salvaEsitoIstruttoriaNdV/",
  salvaEsitoIstruttoriaNdVInBozza: protectedPath + domandeService + "salvaEsitoIstruttoriaNdVInBozza/",
  verificaPiva: protectedPath + verificheService + "verificaPiva/", // verifica singola anagrafica per costituire impresa
  verificaAllegatiRendicontazione: protectedPath + verificheService + "verificaAllegatiRendicontazione/",
  verificaRendicontazione: protectedPath + verificheService + "verificaRendicontazione/",
  verificaCompensative: protectedPath + verificheService + "verificaCompensative/",
  verificaConcessione: protectedPath + verificheService + "verificaConcessione/",
  verificaVariazione: protectedPath + verificheService + "finalizzazioneVariazione/",


  //chiamata di controllo versione
  getBEVersion: unProtectedPath + "getVersion/",


  //chiamate campionamento
  salvaRichieste: protectedPath + campionamentoService + "salvaDurcRichiesta/",
  getBandiPM: protectedPath + campionamentoService + "getBandiPM/",
  cercaDomandePerBando:protectedPath + campionamentoService + "cercaDomandePerBando/",
  startCampionamento:protectedPath + campionamentoService + "startCampionamento/",
  richiediDurc:protectedPath + campionamentoService + "durcManuale/",

  //chiamate autorizzazioni/abilitazione

  getHomepageAutorizzazione: protectedPath + autorizzazioneService + "getHomepageAutorizzazione/",
  autorizzazioneLR: protectedPath + autorizzazioneService + "autorizzazioneLR/",
  autorizzaCompilatore: protectedPath + autorizzazioneService + "autorizzaCompilatore/",
  accettaAuth: protectedPath + autorizzazioneService + "accettaAuth/",
  assegnaCompilatore: protectedPath + autorizzazioneService + "assegnaCompilatore/",
  revocaCompilatore: protectedPath + autorizzazioneService + "revocaCompilatore/",

  //chiamate variazioni
  getVariazione: protectedPath + variazioniService + "getVariazione/",
  getVariazioneObj: protectedPath + variazioniService + "getVariazioneObj/",
  saveVariazione: protectedPath + variazioniService + "saveVariazione/",
  saveVariazioneObj: protectedPath + variazioniService + "saveVariazioneObj/",
  saveVariazioneInfocamere: protectedPath + variazioniService + "saveVariazioneInfocamere/",
  finalizzaVariazioni: protectedPath + variazioniService + "finalizzaVariazioni/",
  getSingoloAllegato: protectedPath + variazioniService + "getSingoloAllegato/",
  modificheVariazioni: protectedPath + variazioniService + "modificheVariazioni/",
  istruttoriaVariazione: protectedPath + variazioniService + "istruttoriaVariazione/",
  salvaIstruttoriaVariazione: protectedPath + variazioniService + "salvaIstruttoriaVariazione/",
  annullaVariazione: protectedPath + variazioniService + "annullaVariazione/",





  //chiamate report
  domandePerBando: protectedPath + reportService + "domandePerBando/",
  
}






