import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { RequestService } from './request.service';
import { FaseCompilatore } from '../Util/DTO/DTOdomande/FaseCompilatore';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';


export const KEYS = {
  DETTAGLIO_BANDO: 'dettaglioBando',
  FORME_GIURIDICHE_BANDO: 'formeGiuridicheBando',
  DOMANDA: 'domanda',
  DOMANDA_CON_FASE: 'domandaConFase',
  SOGGETTO_RICHIEDENTE: 'soggettoRIchiedente',
  NDV_META: "meta_ndv",
  NDV_BLOCCHI_CHIUSI: "ndv_blocchi_chiusi",
  DATA_SERVER: "data_server",
  CAP: "cap",
  CONCEDIBILE: "concedibile",
  INTEGRAZIONI: "integrazioni",
};


@Injectable({
  providedIn: 'root'
})

export class DataService {


  private objtomanage: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessage = this.objtomanage.asObservable();

  private objtomanageImpresa: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageImpresa = this.objtomanageImpresa.asObservable();

  private objtomanageSoccorso: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageSoccorso = this.objtomanageSoccorso.asObservable();


  private objVoctomanage: BehaviorSubject<any> = new BehaviorSubject({});
  currentVocMessage = this.objVoctomanage.asObservable();


  private objToManageForVoci: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageForVoci = this.objToManageForVoci.asObservable();


  private objToManageForIstruttoriaFasi: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageForIstruttoriaFasi = this.objToManageForIstruttoriaFasi.asObservable();

  private objToManageForListeIstruttoriaFasiCompilate: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageForListeIstruttoriaFasiCompilate = this.objToManageForListeIstruttoriaFasiCompilate.asObservable();

  //osservabile per il NDV, contiente tutte le fasi del bando e tutti i risultati
  private objToManageNdvFasi: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageNdvFasi = this.objToManageNdvFasi.asObservable();


  private objToManegeForGraduatoria: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageForGraduatoria = this.objToManegeForGraduatoria.asObservable();

  private sendCookie: BehaviorSubject<any> = new BehaviorSubject({});
  currentCookie = this.sendCookie.asObservable();

  private isReady: BehaviorSubject<any> = new BehaviorSubject({});
  currentIsReady = this.isReady.asObservable();

  private dataServer: BehaviorSubject<any> = new BehaviorSubject({});
  currentdataServer = this.dataServer.asObservable();

  private listIntegrazioni: BehaviorSubject<any> = new BehaviorSubject({});
  currentdataIntegrazione = this.listIntegrazioni.asObservable();

  //ottimizazione eventi voce di costo
  private optmizeVociDiCosto: BehaviorSubject<any> = new BehaviorSubject({});
  optmizeVoci = this.optmizeVociDiCosto.asObservable();


  private objToManegeForFaseCompilatore: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageForFaseCompilatore = this.objToManegeForFaseCompilatore.asObservable();

  //per aggiornamento fase compilatori componenti paralleli voci di costo
  private objToManegeVociSpesaIdFaseCompilatore: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageVociSpesaIdFaseCompilatore = this.objToManegeVociSpesaIdFaseCompilatore.asObservable();

  private objtomanageEsitoBlocchiRendicontazione: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageEsitoBlocchiRendicontazione = this.objtomanageEsitoBlocchiRendicontazione.asObservable();


  private objtomanageFaseSaldo: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageFaseSaldo = this.objtomanageFaseSaldo.asObservable();

  private objtomanageListaAnticipi: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageListaAnticipi = this.objtomanageListaAnticipi.asObservable();


  private objtomanageEsitoNdV: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageEsitoNdV = this.objtomanageEsitoNdV.asObservable();


  private objtomanageEsitoIstruttoreRendicontazioneSpese: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageEsitoIstruttoreRendicontazioneSpese = this.objtomanageEsitoIstruttoreRendicontazioneSpese.asObservable();


  private objtoManageVociDiCostoElaborate: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageVociDiCostoElaborate = this.objtoManageVociDiCostoElaborate.asObservable();

  private objtoManageIsFromCompila: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageIsFromCompila = this.objtoManageIsFromCompila.asObservable();


  private objtoManageALIQUOTA: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageALIQUOTA = this.objtoManageALIQUOTA.asObservable();

  private objtoManageREFRESH: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageREFRESH = this.objtoManageREFRESH.asObservable();

  private objtoManageAnticipoCorrenteIstr: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageAnticipoCorrenteIstr = this.objtoManageAnticipoCorrenteIstr.asObservable();

  private objtoManageListaErogazioni: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageListaErogazioni = this.objtoManageListaErogazioni.asObservable();

 
  private objtoManageNewCalcoliEsitoRendIst: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageNewCalcoliEsitoRendIst = this.objtoManageNewCalcoliEsitoRendIst.asObservable();

  private objtomanageBlocchi: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageBlocchi = this.objtomanageBlocchi.asObservable();


  private objtomanageBlocchiRendi: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageBlocchiRendi = this.objtomanageBlocchiRendi.asObservable();

  private objtomanageFaseIstruttoreRend: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageFaseIstruttoreRend = this.objtomanageFaseIstruttoreRend.asObservable();

  private objCap: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageCap = this.objCap.asObservable();

  private objConcedibile: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageConcedibile = this.objConcedibile.asObservable();

  private isTabErogazione: BehaviorSubject<any> = new BehaviorSubject({});

  private isForfe: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageForfe = this.isForfe.asObservable();

  private alreadySaved: BehaviorSubject<any> = new BehaviorSubject({});
  currentMessageForAlreadySaved = this.alreadySaved.asObservable();

  bkDomanda: any
  lockUpDateDomandaPerVociCosto: boolean = false
  allegatiFinalizzazione: any[];

  superAdminAllowedToAvoidChiusuraBandoControl: boolean = true;


  constructor(private cookieService: CookieService) {


    /*
        this.deamonFunction(1000, () => {
    
    
    
          if (
            this.checkBandoForVociDiCosto(this.getBando()) &&
            this.checkDomandaForVociDiCosto(this.getDomanda()) &&
            this.lockUpDateDomandaPerVociCosto && (JSON.stringify(this.bkDomanda) != JSON.stringify(this.getDomanda()))) {
    
    
    
            this.bkDomanda = this.getDomanda()
    
    
    
            let newMessage: any = {
              "domanda": this.getDomanda(),
              "bando": this.getBando()
            }
            if (newMessage != {}) {
    
    
              this.objToManageForVoci.next(newMessage)
            }
          }
    
    
    
        })
    
    */
    this.currentMessage.subscribe(message => {
      if (message != null)
        this.allegatiFinalizzazione = this.aggiornaAllegatiList(message[KEYS.DETTAGLIO_BANDO]);
    })

  }
  passdataServer(message: any) {
    let newObj = this.dataServer.getValue();
    newObj[KEYS.DATA_SERVER] = message;
    this.dataServer.next(newObj);
  }

  getDataServer() {
    return this.dataServer.getValue()[KEYS.DATA_SERVER];
  }
  passNdvIstruttorieMetaDati(message: any) {
    let newObj = this.objToManageNdvFasi.getValue();
    newObj[KEYS.NDV_META] = message;
    this.objToManageNdvFasi.next(newObj);
  }

  getNdvIstruttorieMetaDati() {
    return this.objToManageNdvFasi.getValue()[KEYS.NDV_META];
  }

  passNdvIstruttorieBlocchiChiusi(message: any) {
    let newObj = this.objToManageNdvFasi.getValue();
    newObj[KEYS.NDV_BLOCCHI_CHIUSI] = message;
    this.objToManageNdvFasi.next(newObj);
  }

  getNdvIstruttorieBlocchiChiusi() {
    return this.objToManageNdvFasi.getValue()[KEYS.NDV_BLOCCHI_CHIUSI];
  }


  passbando(message: any) {

    let newObj = this.objtomanage.getValue();
    newObj[KEYS.DETTAGLIO_BANDO] = message;

    if (newObj != null)
      this.allegatiFinalizzazione = this.aggiornaAllegatiList(newObj[KEYS.DETTAGLIO_BANDO]);

    this.objtomanage.next(newObj);
  }

  passDomandaEBando(domanda, bando) {
    let newObj = this.objtomanage.getValue();
    let idTaskBPM = null;

    if (newObj.domanda != null && newObj.domanda.idTaskBPM != null) {
      idTaskBPM = newObj.domanda.idTaskBPM;
    }
    newObj['domanda'] = domanda;

    if (idTaskBPM != null) {
      newObj.domanda.idTaskBPM = idTaskBPM;
    }

    newObj[KEYS.DETTAGLIO_BANDO] = bando;

    if (newObj != null && bando != null)
      this.allegatiFinalizzazione = this.aggiornaAllegatiList(newObj[KEYS.DETTAGLIO_BANDO]);



    this.objtomanage.next(newObj);
  }

  passdomanda(message: any) {
    let newObj = this.objtomanage.getValue();
    let idTaskBPM = null;

    if (newObj.domanda != null && newObj.domanda.idTaskBPM != null) {
      idTaskBPM = newObj.domanda.idTaskBPM;
    }
    newObj['domanda'] = message;

    if (idTaskBPM != null) {
      newObj.domanda.idTaskBPM = idTaskBPM;
    }
    this.objtomanage.next(newObj);
  }

  passformagiuridica(message: any) {
    let newObj = this.objtomanage.getValue();
    newObj['formagiuridica'] = message;
    this.objtomanage.next(newObj);
  }

  passallbandi(message: any) {
    let newObj = this.objtomanage.getValue();
    newObj['allbandi'] = message;
    this.objtomanage.next(newObj);
  }

  passallutility(message: any) {
    let newObj = this.objtomanage.getValue();
    newObj['allutility'] = message;
    this.objtomanage.next(newObj);
  }

  passanagraficaimpresa(message: any) {
    let newObj = this.objtomanageImpresa.getValue();
    newObj['anagImpresa'] = message;
    this.objtomanageImpresa.next(newObj);
  }

  passSoggettoRichiedente(soggetto: any) {
    let newObj = this.objtomanage.getValue();
    newObj[KEYS.SOGGETTO_RICHIEDENTE] = soggetto;
    this.objtomanage.next(newObj);
  }

  getSoggettoRichiedete() {
    return this.objtomanage.getValue()[KEYS.SOGGETTO_RICHIEDENTE];
  }


  updateMap(key: string, objToUpdate: any) {
    let newObj = this.objtomanage.getValue();
    newObj[key] = objToUpdate;
    this.objtomanage.next(newObj);
  }

  setFormeGiuridiche(formeGiuridiche: any[]) {
    let newObj = this.objtomanage.getValue();
    newObj[KEYS.FORME_GIURIDICHE_BANDO] = formeGiuridiche;
    this.objtomanage.next(newObj);
  }

  getFormeGiuridiche(): any[] {
    return this.objtomanage.getValue()[KEYS.FORME_GIURIDICHE_BANDO];
  }

  getImpresa(): any {
    return this.objtomanageImpresa.getValue()['anagImpresa'];
  }

  getDomanda() {
    return this.objtomanage.getValue()[KEYS.DOMANDA];
  }

  getBando() {

    return this.objtomanage.value[KEYS.DETTAGLIO_BANDO];
  }

  getFormGiu() {
    return this.objtomanage.getValue()['formagiuridica'];
  }


  resetData() {
    this.objtomanage.next({});
  }

  getVociDiCostoPerAggregazione() {
    return this.objVoctomanage.getValue()['vociDiCosto'];
  }

  passVociDiCostoPerAggregazione(message) {
    let newObj = this.objVoctomanage.getValue();
    newObj['vociDiCosto'] = message;
    this.objVoctomanage.next(newObj)
  }


  deamonFunction(timeCylce: number, data: () => any) {



    (async () => {

      while (true) {


        await new Promise(resolve => setTimeout(resolve, timeCylce)).then(x => {
          data()
        })
      }

    })();


  }

  checkDomandaForVociDiCosto(domanda: any) {
    try {
      return (domanda != null && domanda.id != null && domanda != {}) && (
        domanda.anagraficaPersonaFisica != null ||
        domanda.anagraficaImpresa != null ||
        domanda.anagraficaEnte != null)
    } catch {
      return false
    }
  }

  checkBandoForVociDiCosto(bando) {
    return bando != null && bando.id != null && bando != {}
      && bando["ruolo"] == undefined
  }
  setLockUpdateDomandaVoci(state: boolean) {
    this.lockUpDateDomandaPerVociCosto = state
  }

  passListaIstruttoriaFasi(message: any) {
    let newObj = this.objToManageForIstruttoriaFasi.getValue();
    newObj['listaIstruttoriaFasi'] = message;
    this.objToManageForIstruttoriaFasi.next(newObj);
  }

  getListaIstruttoriaFasi() {
    return this.objToManageForIstruttoriaFasi.getValue()['listaIstruttoriaFasi'];
  }

  getAllegatiVociDiCosto() {
    return this.objtomanageSoccorso.getValue()['allegatiVociDiCosto'];
  }

  passAllegatiVociDiCosto(message: any) {
    let newObj = this.objtomanageSoccorso.getValue();
    newObj['allegatiVociDiCosto'] = message;
    this.objtomanageSoccorso.next(newObj);
  }

  getBlocchiSalvati() {
    return this.objtomanageBlocchi.getValue()['blocchiSalvati'];
  }

  passBlocchiSalvati(message: any) {
    let newObj = this.objtomanageBlocchi.getValue();
    newObj['blocchiSalvati'] = message;
    this.objtomanageBlocchi.next(newObj);
  }

  getBlocchiSalvatiRendi() {
    return this.objtomanageBlocchiRendi.getValue()['blocchiSalvatiRendi'];
  }

  passBlocchiSalvatiRendi(message: any) {
    let newObj = this.objtomanageBlocchiRendi.getValue();
    newObj['blocchiSalvatiRendi'] = message;
    this.objtomanageBlocchiRendi.next(newObj);
  }

  getAllegatiTabAllegati() {
    return this.objtomanageSoccorso.getValue()['allegatiTabAllegati'];
  }

  passAllegatiTabAllegati(message: any) {
    let newObj = this.objtomanageSoccorso.getValue();
    newObj['allegatiTabAllegati'] = message;
    this.objtomanageSoccorso.next(newObj);
  }

  getNoteSoccorso() {
    return this.objtomanageSoccorso.getValue()['noteSoccorso'];
  }

  passNoteSoccorso(message: any) {
    let newObj = this.objtomanageSoccorso.getValue();
    newObj['noteSoccorso'] = message;
    this.objtomanageSoccorso.next(newObj);
  }



  passListaBlocchiCompilati(listeBlocchiCompletati: any) {

    this.objToManageForListeIstruttoriaFasiCompilate.next(listeBlocchiCompletati);
  }

  getListaBlocchiCompilati() {

    return this.objToManageForListeIstruttoriaFasiCompilate.getValue();
  }

  passGraduatoria(graduatoria: any) {
    let newObj = this.objToManegeForGraduatoria.getValue();
    newObj['graduatoria'] = graduatoria;
    this.objToManegeForGraduatoria.next(newObj)

  }
  getGraduatoria() {
    return this.objToManegeForGraduatoria.getValue()['graduatoria'];
  }

  passCookie(condition: any) {
    let newObj = this.sendCookie.getValue();
    newObj['condition'] = condition;
    this.sendCookie.next(newObj)

  }
  getCookie() {
    return this.sendCookie.getValue()['condition'];
  }



  passEsitoNdV(newObj: any) {
    this.objtomanageEsitoNdV.next(newObj)
  }

  getEsitoNdV() {
    return this.objtomanageEsitoNdV.getValue();
  }

  passIsready(condition: any) {
    let newObj = this.isReady.getValue();
    newObj['isReady'] = condition;
    this.isReady.next(newObj)

  }

  getIsReady() {
    return this.isReady.getValue()['isReady'];
  }

  getFaseRendicontazioneDomanda(idDomanda: number) {

    if (idDomanda == null)
      return null;
    let listaDomande: any[] = this.getDomandaConFase()





    if (listaDomande == null || listaDomande == [] ||
      (listaDomande.filter(x => x.id == idDomanda))[0] == null ||
      (listaDomande.filter(x => x.id == idDomanda))[0] == undefined)
      return null



    return (listaDomande.filter(x => x.id == idDomanda))[0].fase
  }

  passFaseCompilatore(obj) {
    this.objToManegeForFaseCompilatore.next({ fase: obj })
  }
  getFaseCompilatore() {
    return this.objToManegeForFaseCompilatore.getValue()
  }

  passFaseSaldo(obj) {
    this.objtomanageFaseSaldo.next(obj)
  }
  getFaseSaldo() {
    let o = this.objtomanageFaseSaldo.getValue()
    if (o != null && o.perSaldoStimata == null)
      o.perSaldoStimata = 50
    return o
  }

  passListaAnticipi(obj: any[]) {

    this.objtomanageListaAnticipi.next(obj);
  }
  getListaAnticipi() {
    return this.objtomanageListaAnticipi.getValue()
  }


  passALIQUOTA(obj: any) {

    this.objtoManageALIQUOTA.next(obj);
  }
  getALIQUOTA() {
    return this.objtoManageALIQUOTA.getValue()
  }

  passREFRESH(obj: boolean ) {

    this.objtoManageREFRESH.next(obj);
  }
  getREFRESH() {
    return this.objtoManageREFRESH.getValue()
  }

  passEsitoIstruttoriaRendicontazioneSpese(obj: any) {

    this.objtomanageEsitoIstruttoreRendicontazioneSpese.next(obj);
  }
  getEsitoIstruttoriaRendicontazioneSpese() {
    return this.objtomanageEsitoIstruttoreRendicontazioneSpese.getValue()
  }

  passAnticipoCorrenteIstr(obj: any) {

    this.objtoManageAnticipoCorrenteIstr.next(obj);
  }
  getAnticipoCorrenteIstr() {
    return this.objtoManageAnticipoCorrenteIstr.getValue()
  }

  passListaErogazioni(obj: number) {

    this.objtoManageListaErogazioni.next(obj);
  }
  getListaErogazioni() {
    return this.objtoManageListaErogazioni.getValue()
  }

  passNewCalcoliEsitoRendIst(obj: any) {

    this.objtoManageNewCalcoliEsitoRendIst.next(obj);
  }
  getListaNewCalcoliEsitoRendIst() {
    return this.objtoManageNewCalcoliEsitoRendIst.getValue()
  }


  resetAll() {


    this.objtomanage.unsubscribe()
    this.objVoctomanage.unsubscribe()
    this.objToManageForVoci.unsubscribe()
    this.objToManageForIstruttoriaFasi.unsubscribe()
    this.objToManageForListeIstruttoriaFasiCompilate.unsubscribe()
    this.objToManegeForFaseCompilatore.unsubscribe()
    this.objtomanageImpresa.unsubscribe
    this.objtomanageFaseSaldo.unsubscribe()
    this.isTabErogazione.unsubscribe();
    this.objToManageNdvFasi.unsubscribe();
    this.objtomanageBlocchi.unsubscribe();
    this.objtomanageBlocchiRendi.unsubscribe();
    this.objCap.unsubscribe();
    this.objConcedibile.unsubscribe();
	  this.isForfe.unsubscribe();
    this.objtoManageNewCalcoliEsitoRendIst.unsubscribe();
    this.objtomanageEsitoIstruttoreRendicontazioneSpese.unsubscribe();
    this.objtoManageVociDiCostoElaborate.unsubscribe();
    this.objtoManageALIQUOTA.unsubscribe();
    this.listIntegrazioni.unsubscribe();

    this.alreadySaved.unsubscribe()

    this.listIntegrazioni = new BehaviorSubject({});
    this.currentdataIntegrazione = this.listIntegrazioni.asObservable();
    this.objtoManageREFRESH.unsubscribe();

    this.objtoManageREFRESH = new BehaviorSubject({});
    this.currentMessageREFRESH = this.objtoManageREFRESH.asObservable();

    this.objtoManageALIQUOTA = new BehaviorSubject({});
    this.currentMessageALIQUOTA = this.objtoManageALIQUOTA.asObservable();

    this.objtomanageEsitoIstruttoreRendicontazioneSpese = new BehaviorSubject({});
    this.currentMessageEsitoIstruttoreRendicontazioneSpese = this.objtomanageEsitoIstruttoreRendicontazioneSpese.asObservable();

    this.objtoManageVociDiCostoElaborate = new BehaviorSubject({});
    this.currentMessageVociDiCostoElaborate = this.objtoManageVociDiCostoElaborate.asObservable();

    this.objtomanageBlocchi = new BehaviorSubject({});
    this.currentMessageBlocchi = this.objtomanageBlocchi.asObservable();

    this.objtoManageNewCalcoliEsitoRendIst = new BehaviorSubject({});
    this.currentMessageNewCalcoliEsitoRendIst = this.objtoManageNewCalcoliEsitoRendIst.asObservable();

    this.objtomanageBlocchiRendi = new BehaviorSubject({});
    this.currentMessageBlocchiRendi = this.objtomanageBlocchiRendi.asObservable();

    this.objToManegeForFaseCompilatore = new BehaviorSubject({});
    this.currentMessageForFaseCompilatore = this.objToManegeForFaseCompilatore.asObservable();

    this.objtomanage = new BehaviorSubject({});
    this.currentMessage = this.objtomanage.asObservable();


    this.objVoctomanage = new BehaviorSubject({});
    this.currentVocMessage = this.objVoctomanage.asObservable();


    this.objToManageForVoci = new BehaviorSubject({});
    this.currentMessageForVoci = this.objToManageForVoci.asObservable();


    this.objToManageForIstruttoriaFasi = new BehaviorSubject({});
    this.currentMessageForIstruttoriaFasi = this.objToManageForIstruttoriaFasi.asObservable();

    this.objToManegeVociSpesaIdFaseCompilatore = new BehaviorSubject({});
    this.currentMessageVociSpesaIdFaseCompilatore =
      this.objToManegeVociSpesaIdFaseCompilatore.asObservable();

    this.objToManageForListeIstruttoriaFasiCompilate = new BehaviorSubject({});
    this.currentMessageForListeIstruttoriaFasiCompilate = this.objToManageForListeIstruttoriaFasiCompilate.asObservable();

    this.optmizeVociDiCosto = new BehaviorSubject({});
    this.optmizeVoci = this.optmizeVociDiCosto.asObservable();


    this.objtomanageImpresa = new BehaviorSubject({});
    this.currentMessageImpresa = this.objtomanageImpresa.asObservable();

    this.objtomanageFaseSaldo = new BehaviorSubject({});
    this.currentMessageFaseSaldo = this.objtomanageFaseSaldo.asObservable();

    this.objToManageNdvFasi  = new BehaviorSubject({});
    this.currentMessageNdvFasi = this.objToManageNdvFasi.asObservable();

    this.objCap = new BehaviorSubject({});
    this.currentMessageCap = this.objCap.asObservable();

    this.objConcedibile = new BehaviorSubject({});
    this.currentMessageConcedibile = this.objConcedibile.asObservable();
	
	this.isForfe = new BehaviorSubject({});
    this.currentMessageForfe = this.isForfe.asObservable();

    this.alreadySaved = new BehaviorSubject({});
    this.currentMessageForAlreadySaved = this.alreadySaved.asObservable();


    this.isTabErogazione = new BehaviorSubject({});


  }

  getAllegatiFinalizzazione(): any[] {
    return this.allegatiFinalizzazione

  }

  passSubTotaleVociDiCosto(newObj: any, index: number) {

    let newBjoRe = {
      value: newObj,
      index: index
    }

    if (newBjoRe != null && newBjoRe.value != null)
      this.optmizeVociDiCosto.next(newBjoRe);
  }



  private aggiornaAllegatiList(bando: any): any[] {


    try {
      //console.log("aggiorno allegati finalizzazione")

      if (bando != null && bando.ricercaBandoAllegati != null) {
        let nuovaListaAllegatiFinalizzazione: any[] = []


        bando.ricercaBandoAllegati.forEach(allegato => {
          if (allegato != null && allegato.autocomposto && allegato.tipoAutocomposto != null) {
            nuovaListaAllegatiFinalizzazione.push(allegato)
          }

        });

        //console.log("nuovi allegati finalizzazione")
        //console.log(this.allegatiFinalizzazione)

        return nuovaListaAllegatiFinalizzazione
      }

    } catch {
      console.log("ERRORE ALLEGATI FINALIZZAZIONE")
    }
  }

  /**
   *
   * funzione per controllare se un bando è chiuso o no,
   * la funzione controlla lo stato del bando solo se la domanda è in bozza
   *
   * @param bando bando da controllare
   * @param domanda relativa domanda
   *
   *
   * @returns false quando il bando non è chiuso (o è chiuso e il super admin è esonerato dai controlli), true se
   * il bando è effettivamente chiuso
   */
  public isBandoChiusoAndDomandaInBozza(bando: any, domanda: any): boolean {



    if ((domanda == null) || (domanda.stato != null && domanda.stato.id != 1)) {
      // in questo caso la domanda o non è pronta o non è in bozza, non procedo oltre
      return false
    }


    //se al super admin è permesso ignorare i controlli, controllo se l'utenza corrente sia un super admin
    if (this.superAdminAllowedToAvoidChiusuraBandoControl) {
      let tipoDiUtenza: number = +(JSON.parse(this.cookieService.get('usL')).loggedIdProfile);
      let loggedUserId: number = +(JSON.parse(this.cookieService.get('usL')).loggedUserId);
      let loggedUser: string = (JSON.parse(this.cookieService.get('usL')).loggedUser);
      if (loggedUserId == 0 && loggedUser=="SuperAdmin") {
        //se si tratta del super admin, che il bando sia chiuso o no, comunque supera il controllo
        return false
      }


    }

    //se l'utente non è esonerato dai controlli, passo a controllare lo stato del bando
    if (bando != null && bando.stato != null && bando.stato.id == 4) {//id stato 4 = "chiuso"
      return true;
    }

    return false

  }
  //objToManegeVociSpesaIdFaseCompilatore

  passIdFaseCompilatore(newObj) {

    this.objToManegeVociSpesaIdFaseCompilatore.next(newObj);
  }

  getIdFaseCompilatore() {
    return this.objToManegeVociSpesaIdFaseCompilatore.getValue()
  }

  passDomandaConFase(domanda) {
    let newObj = this.objtomanage.getValue();
    newObj['domandaConFase'] = domanda;
    this.objtomanage.next(newObj);
  }

  getDomandaConFase() {
    return this.objtomanage.getValue()[KEYS.DOMANDA_CON_FASE];
  }

  passEsitoBlocchiFaseIstruttoreRend(obj) {
    this.objtomanageEsitoBlocchiRendicontazione.next(obj)
  }
  getEsitoBlocchiFaseIstruttoreRend() {
    return this.objtomanageEsitoBlocchiRendicontazione.getValue()
  }

  passIsTabErogazione(obj) {
    this.isTabErogazione.next(obj);
  }
  getIsTabErogazione() {
    return this.isTabErogazione.getValue()
  }


  passVociDiCostoElaborate(obj) {
    this.objtoManageVociDiCostoElaborate.next(obj);
  }
  getVociDiCostoElaborate() {
    return this.objtoManageVociDiCostoElaborate.getValue()
  }

  passIsFromCompila(obj) {
    this.objtoManageIsFromCompila.next(obj);
  }
  getIsFromCompila() {
    return this.objtoManageIsFromCompila.getValue()
  }
  passFaseIstruttoreRend(obj) {
    this.objtomanageFaseIstruttoreRend.next(obj);
  }
  getFaseIstruttoreRend() {
    return this.objtomanageFaseIstruttoreRend.getValue()
  }

  passCap(cap) {
    let newObj = this.objCap.getValue();
    newObj['cap'] = cap;
    this.objCap.next(newObj);
  }

  getCap() {
    return this.objCap.getValue()[KEYS.CAP];
  }

  passConcedibile(concedibile) {
    let newObj = this.objConcedibile.getValue();
    newObj['concedibile'] = concedibile;
    this.objConcedibile.next(newObj);
  }

  getConcedibile() {
    return this.objConcedibile.getValue()[KEYS.CONCEDIBILE];
  }


  passIsForfe(obj) {
    this.isForfe.next(obj);
  }
  getIsForfe() {
    return this.isForfe.getValue()
  }

  passIntegrazione(message: any) {
    let newObj = this.listIntegrazioni.getValue();
    if(message.allegati != null && newObj[KEYS.INTEGRAZIONI]!=null ){
      message["voci"] = newObj[KEYS.INTEGRAZIONI].voci
    }
    else if(message.voci != null && newObj[KEYS.INTEGRAZIONI]!=null ){
      message["allegati"] = newObj[KEYS.INTEGRAZIONI].allegati
    }
    newObj[KEYS.INTEGRAZIONI] = message;
    this.listIntegrazioni.next(newObj);
  }

  getIntegrazione() {
    return this.listIntegrazioni.getValue()[KEYS.INTEGRAZIONI];
  }

  passIsSaved(obj) {
    this.alreadySaved.next(obj);
  }
  getIsSaved() {
    return this.alreadySaved.getValue()
  }

  public getAliquotaFormattata(domanda: any, aliquota: any): number {

  if(domanda!= null && domanda.stato.id != null && domanda.stato.id <4){
    if(aliquota!= null && aliquota.aliquota != null){
      return aliquota.aliquota
    }
  }if(domanda!= null && domanda.stato.id != null && domanda.stato.id >4 && domanda.stato.id <11){
    if(aliquota!= null && aliquota.aliquota != null){
      let aliquota1 = aliquota.aliquotaTecnica != null ? aliquota.aliquotaTecnica : aliquota.aliquota;
      return aliquota1
    }
  }if(domanda!= null && domanda.stato.id != null && domanda.stato.id >=11){
    if(aliquota!= null && aliquota.aliquota != null){
      let aliquota1 = aliquota.aliquotaNucleo != null ? aliquota.aliquotaNucleo : aliquota.aliquotaTecnica != null ? aliquota.aliquotaTecnica : aliquota.aliquota;
      return aliquota1
    }
  }

  }
  
}

