import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { RequestService } from '../../services/request.service';
import { sha256 } from 'js-sha256';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import * as _ from 'lodash';
import { DTOUser } from '../../Util/DTO/DTOUser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MaurizioPrinterService } from '../../services/printer/maurizio/maurizio-printer.service';
import { DataService } from '../../services/data.service';
import { alertEnviroment, spidBan} from '../../../environments/custom/environment.locale'
import { REGOLEALLEGATI, UtilityForDomande } from '../../pages/gestioneDomande/utility';
import { DTOFile } from '../../Util/DTO/DTOFile';
const dangerusTypes: string[] = [
  "exe", "pif", "application", "gadget",
  "msi", "msp", "msp", "com", "src", "hta",
  "cpl", "msc", "bat", "cmd", "vb", "vbs",
  "vbe", "js", "jse", "ws", "wsf", "wsc",
  "wsh", "ps1", "ps1xml", "ps2", "ps2xml",
  "psc1", "psc2", "msh", "msh1", "msh2", "mshxml",
  "msh1xml", "msh2xml", "scf", "lnk", "inf", "reg",
  "ddl"
]
declare var jQuery: any;
class user {
  loggedUser: string;
  loggedUserId: number;
  token: string;
  loggedIn: boolean;
  impersonificatore: boolean;
  email: string;
  loggedIdProfile: string;
  cicle: any[] = [1, 2, 3]

  msg: any
  constructor() {
    this.loggedUser = '';
    this.loggedUserId = null;
    this.token = '';
    this.email = '';
    this.loggedIn = false;
    this.impersonificatore = false;
    this.loggedIdProfile = '';
  }

}

class spid {
  xml: string;
  url: string;

  constructor() {
    this.xml = '';
    this.url = '';
  }
}

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('templateAuth') templateAuth: TemplateRef<any>;
  @ViewChild('templateLoading') templeteLoading: TemplateRef<any>;
  @ViewChild('templateRegistrazioneSpid') templateRegistrazioneSpid: TemplateRef<any>;
  @ViewChild('templateRegistrazioneAuth') templateRegistrazioneAuth: TemplateRef<any>;
  @ViewChild('templateRisultato') templateRisultato: TemplateRef<any>;
  @ViewChild('templateRisultatoAuth') templateRisultatoAuth: TemplateRef<any>;
  cookieValue = 'UNKNOWN';
  user: string;
  userspid: any;
  psw: string;
  msg: string;
  msgAutorizzato: string;
  submitted = false;
  modalRef1: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  expiredDate: any;
  pswNew: string = '';
  pswConfirm: string = '';
  pswAttuale: string;
  mail: string;
  pswdimenticataForm: FormGroup;
  esito: boolean = false;
  servermsg: string = '';
  rispSalvataggio: any;
  spide: boolean = false;
  htmlspid: string;
  spider: spid;
  lengthpsw: boolean = true;
  pswsbagliata: boolean = false;
  missmatch: boolean = false;
  erroreserver: boolean = false;
  operazioneriuscita: boolean = false;
  msglung: string;
  modalRef: BsModalRef;
  askRegistrati: boolean = false
  errore: boolean = false;
  userl = new user();
  usercp = new DTOUser();
  userSpid = new DTOUser();
  hiddenSpid = false
  customSelected: string;
  envAlert = alertEnviroment
  spidLogin = spidBan
  registerForm: FormGroup;
  registerFormAuth: FormGroup;
  allegato: any
  finalAuth:any
  autorizzato: boolean;

  date = new Date(Date.now());

  informativaDatiPersonali: string;
  constructor(private cookieService: CookieService, private routerAuth: Router,
    private reqService: RequestService, private formBuilder: FormBuilder, private modalService: BsModalService,
    private httpClient: HttpClient, private mPrinter: MaurizioPrinterService, private activatedRoute: ActivatedRoute, private data: DataService) {


    let node = document.createElement('script');

    let listOfDIpendence: { type: string, src: string }[] =
      [/**/
        {
          src: "https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.2/css/bootstrap.min.css",
          type: "text/css"
        },
        {
          src: "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.13.1/css/bootstrap-select.min.css",
          type: "text/css"
        },
        {
          src: "https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/js/bootstrap.bundle.min.js",
          type: "text/javascript"
        },

        {
          src: "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.13.1/js/bootstrap-select.min.js",
          type: "text/javascript"
        },
      ]

    /*
        for (let rel of listOfDIpendence) {
          node.src = rel.src;
          node.type = rel.type
          node.async = true;
          node.charset = 'utf-8';
          document.getElementsByTagName('head')[0].appendChild(node);
        }
    */


  }

  ngOnInit() {


    this.registerFormAuth = this.formBuilder.group({
      nome: ["", Validators.required],
      cognome: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      privacy: [false, Validators.requiredTrue],
      privacy2: [false],
      codicefiscale: ["", [Validators.required, Validators.minLength(16), Validators.maxLength(16)]],
      cellulare: ["", Validators.pattern('^(\\+?)[0-9]*$')],
      messaggio: ["", [Validators.required]],
    });

    this.cookieService.delete('usL');
    this.cookieService.delete('spid');
    if(this.isValidJsonString(this.cookieService.get('usOL'))){
      this.cookieService.delete('usOL');
    }



    this.activatedRoute.queryParams.subscribe(params => {
      let date = params['redirect'];
      if (date == '1') {
        this.cookieService.delete('usL');
        this.cookieService.delete('spid');
        this.hiddenSpid = true
        this.routerAuth.navigate(["/login"])
        this.data.passCookie(true)
      }
      else if (date == '0') {
        this.hiddenSpid = false
        this.routerAuth.navigate(["/login"])
        this.data.passCookie(false)
        window.alert("autenticazione da Infocamere fallita, riprovare")
      }
      else if (date == '2'){
        this.cookieService.delete('usL');
        this.cookieService.delete('spid');
        this.hiddenSpid = false
        this.routerAuth.navigate(["/istr-auth"])
        this.data.passCookie(false)
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      let date = params['spid'];
      if (date == '1') {
        this.modalRef1 = this.modalService.show(this.templeteLoading, {
          backdrop: 'static',
          ignoreBackdropClick: true
        });
        this.cookieService.delete('usL');
        this.cookieService.delete('spid');


        this.reqService.loginWithSpid().subscribe(risposta => {
          if (!risposta.autenticato) {

            this.modalRef1.hide()
            window.alert("Autenticazione fallita, riprovare!")

            this.routerAuth.navigate(["/login"])
          }
          if (risposta.autenticato && !risposta.registrato) {
            this.modalRef1.hide()

            this.routerAuth.navigate(["/login"])
            this.reqService.downloadInformativaDatiPersonali().subscribe(
              data => {
                this.informativaDatiPersonali = data.datiPersonalibase64.toString();
              }
            );
            this.userSpid =new DTOUser()
            this.userSpid.cellulare = risposta.user.cellulare
            this.userSpid.nome = risposta.user.nome
            this.userSpid.codiceFiscale = risposta.user.codiceFiscale
            this.userSpid.cognome = risposta.user.cognome
            this.userSpid.email = risposta.user.email
            this.askRegistrati = true;

            this.registerForm = this.formBuilder.group({
              nome: [{value:this.userSpid.nome , disabled:true}, Validators.required],
              cognome: [{value:this.userSpid.cognome, disabled:true}, Validators.required],
              email: [this.userSpid.email, [Validators.required, Validators.email]],
              privacy: [false, Validators.requiredTrue],
              privacy2: [false],
              codicefiscale: [{value:this.userSpid.codiceFiscale, disabled:true}, [Validators.required, Validators.minLength(16), Validators.maxLength(16)]],
              sesso: [''],
              cellulare: [this.userSpid.cellulare, Validators.pattern('^(\\+?)[0-9]*$')],
              datanascita: [''],
              luogonascita: ['']
            });
            this.modalRef2 = this.modalService.show(this.templateRegistrazioneSpid, {
              backdrop: 'static',
              ignoreBackdropClick: true
            });

          }
          if (risposta.autenticato && risposta.registrato) {

            this.userl.loggedIn = true;
            this.userl.token = risposta.token;
            this.userl.email = risposta.user.email;
            this.userl.loggedIdProfile = risposta.user.profilo.id;
            this.userl.loggedUser = risposta.user.nome;
            this.userl.loggedUserId = risposta.user.id;
            this.userl["spid"] = true;
            //this.userl["piva"]=risposta.user.pIva
            if (this.userl.loggedIdProfile == "3") {
              this.userl["isPm"] = risposta.user.pm
            }
            this.cookieService.set('usL', JSON.stringify(this.userl), 1);
            (async () => {
              await new Promise(resolve => setTimeout(resolve, 2000));
              this.modalRef1.hide()
              this.routerAuth.navigate(['/']);
            })();


          }

        })
      }
      else if (date == '0') {

        this.routerAuth.navigate(["/login"])

        window.alert("Autenticazione fallita, riprovare")
      }

    });
    console.log('Versione FE: 04/07/2023');
    this.reqService.getBeVerison().subscribe(versioneBE => {
      console.log("Versione Be: " + versioneBE);
    })

    localStorage.setItem('runMode', 'NONE');



    this.spider = new spid();
    this.pswdimenticataForm = this.formBuilder.group({


      email: ['', [Validators.required, Validators.email]],
    });


    //console.log(this.cookieService.get('Test'));
    //this.cookieService.set( 'Test', JSON.stringify(this.user) );
    //


    this.mPrinter.printSviluppo().out('ciao sono Maurizio');
  }

  get f() {
    return this.pswdimenticataForm.controls;
  }

  get foo() {
    return this.registerForm.controls;
  }
  get foo2() {
    return this.registerFormAuth.controls;
  }
  login(template: TemplateRef<any>) {

    this.errore = false;
    this.doAuthentication(this.user, this.psw, template);


  }


  doAuthentication(email: string, pass: string, template: TemplateRef<any>) {



    if (pass != null && pass.length > 0) {
      pass = sha256.update(pass).hex().slice(0, 44);
    }

   // console.log("Email cript[" + pass + "]")
    this.reqService.loginRequest(email, pass).subscribe(risposta => {
      if (risposta.autenticato && (risposta.user.primoAccesso || risposta.superAdmin)) {
        if (risposta.autenticato) {
          this.userl.loggedIn = true;
          this.userl.token = risposta.token;


          // se si tratta del super admin
          if (risposta.superAdmin) {
            this.userl.loggedUser = 'SuperAdmin';
            this.userl.loggedIdProfile = '1';
            this.userl.loggedUserId = 0;
          } else {
            this.userl.email = risposta.user.email;
            this.userl.loggedIdProfile = risposta.user.profilo.id;
            this.userl.loggedUser = risposta.user.nome;
            this.userl.loggedUserId = risposta.user.id;
            this.userl["piva"] = risposta.user.pIva
            if(risposta.impersonificatore != null){
              this.userl.impersonificatore = risposta.impersonificatore;
            }
            if (this.userl.loggedIdProfile == "3") {
              this.userl["isPm"] = risposta.user.pm || risposta.user.sa
              if(risposta.user.sa){
                this.userl["sa"] = true
              }
            }
          }
          if (isNaN(risposta.piva)) {
            this.msg = risposta.piva;
            this.modalRef1 = this.modalService.show(this.templateAuth, {

              backdrop: 'static',
              ignoreBackdropClick: true
            });
          }
          // if(this.userl.impersonificatore){
          //   this.cookieService.set('impersonificatore', JSON.stringify(this.userl.impersonificatore),1);
          // }
          this.cookieService.set('usL', JSON.stringify(this.userl), 1);
          this.routerAuth.navigate(['/']);
          return true;
        }

      } else if (!risposta.autenticato) {

        this.errore = true;
        this.autorizzato = risposta.autorizzato
        if(!this.autorizzato){
          this.msgAutorizzato = "L'utente non è autorizzato.";
        } else {
          this.msg = 'Credenziali d\'accesso errate';
        }
        
        
        

        (async () => {
          await this.delay(10000);
          this.errore = false;
          this.autorizzato = true;
        })();


        this.routerAuth.navigate(['/login']);
        return false;
      } else {
        this.usercp = _.cloneDeep(risposta.user);
        this.modalRef = this.modalService.show(template, {
          backdrop: 'static',
          ignoreBackdropClick: true
        });
      }
    },
      err => {
        if (err.status == 401 || err.status == 403) {
          this.errore = true;
          this.msg = 'Credenziali d\'accesso errate';
        } else if (err.status == 500) {
          this.errore = true;
          this.msg = 'Errore interno del server';
        } else {
          this.errore = true;
          this.msg = 'Errore nell\'autenticazione';
        }

        (async () => {
          await this.delay(10000);
          this.errore = false;
        })();


        return false;
      }
    );

  }


  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  close() {
    this.errore = false;
  }

  registrati() {
    this.askRegistrati = true

    jQuery('#modaleregistrazione').modal('show');
    jQuery('#modaleregistrazione')[0].style.display = "block";
    document.getElementsByClassName('modal  animated fade bd-example-modal-lg')[0].parentElement.style.backgroundColor = 'rgba(0, 0, 0, 0.4)';
    //this.routerAuth.navigate(['/registrazione'])
  }

  salva() {

  }


  chiudi(template: TemplateRef<any>) {
    this.pswAttuale = '';
    this.pswConfirm = '';
    this.pswNew = '';
    this.msglung = '';
    this.pswsbagliata = false;
    this.missmatch = false;
    this.erroreserver = false;
    this.lengthpsw = true;
    this.modalRef.hide();
    if (this.operazioneriuscita) {
      this.routerAuth.navigate(['/login']);
    }
  }

  reset() {
    this.pswsbagliata = false;
    this.missmatch = false;
    this.erroreserver = false;
    this.operazioneriuscita = false;
    var objtosSend = {};
    var passA;
    var passN;
    if (this.pswAttuale != null && this.pswAttuale.length > 0) {
      passA = sha256.update(this.pswAttuale).hex().slice(0, 44);
    }
    this.reqService.loginRequest(this.usercp.codiceFiscale, passA).subscribe(risposta => {
      //console.log(risposta)
      if (risposta.autenticato != false) {
        if (this.pswNew == this.pswConfirm) {
          passN = sha256.update(this.pswNew).hex().slice(0, 44);
          this.usercp.password = passN;
          this.usercp.primoAccesso = true;
          this.usercp["datiAccessoUtenti"] = null
          if (!this.lengthpsw) {
            this.reqService.saveuserRequest(this.usercp).subscribe(risposta => {
              if (risposta.esito) {
                this.operazioneriuscita = true;
                /*
              jQuery("#modalResetPass").appendTo('body')
             jQuery("#modalResetPass").modal("hide");
             jQuery('.modal-backdrop').remove()

             this.lS.clear();
             this.router.navigate(['/login'])
             */
              } else {
                this.erroreserver = true;
              }
              ////console.log(risposta)
            });
          }
        } else {
          this.missmatch = true;
        }
      } else {
        this.pswsbagliata = true;
      }


    });
  }

  lunghezza() {
    if (this.pswNew.length >= 5 && this.pswConfirm.length >= 5) {
      this.lengthpsw = false;
      this.msglung = '';
    } else {
      this.lengthpsw = true;
      this.msglung = 'La Lunghezza della password deve essere di almeno 5 caratteri';

    }
  }

  redirect(template: TemplateRef<any>) {
    this.routerAuth.navigate(['/login']);
    this.modalRef.hide();

  }

  onSubmit(template: TemplateRef<any>) {
    this.submitted = true;
    if (this.pswdimenticataForm.invalid) {
      return;
    } else {
      this.modalRef.hide();
      this.modalRef = this.modalService.show(template, {
        backdrop: 'static',
        ignoreBackdropClick: true


      });
      let user = new DTOUser();
      let pass;


      user.dataNascita = '2012-12-21';
      user.email = this.pswdimenticataForm.get('email').value.toLowerCase();
      pass = Math.random().toString(36).slice(-8);
      user.passwordToMail = pass;
      user.password = sha256.update(pass).hex().slice(0, 44);
      this.reqService.recuperaPasswordRequest(user).subscribe(risposta => {
        this.esito = risposta.esito;
        this.servermsg = risposta.messaggio;
        this.rispSalvataggio = risposta;
      });

    }

  }

  pswdimenticata(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {

      backdrop: 'static',
      ignoreBackdropClick: true
    });
  }

  chiudimb(template: TemplateRef<any>) {
    this.modalRef.hide();


  }

  spid() {
    if(!this.spidLogin)
      return
    let promise = new Promise<boolean>((resolve) => {
      this.reqService.spidRequest().subscribe(risposta => {
        this.spider = new spid();
        this.spider.xml = risposta.xmlAuthRequest;
        this.spider.url = risposta.destinationUrl;
        resolve(true);
      }
      );
    });

    promise.then(value => {

      if (value) {

        (<HTMLInputElement>document.getElementById('saml')).value = this.spider.xml;

        document.getElementById('spidprova1').click();

        //this.cookieService.set('spid',JSON.stringify(this.spider) ,1);
        //this.reqService.spidauth(this.spider.url).subscribe(risposta => {

        //window.location.href=risposta.url
        //console.log(risposta)
        //})
      }
    })

  }



  chiudila($event) {
    jQuery('#modaleregistrazione')[0].style.display = "none";
    jQuery('#modaleregistrazione').modal('hide');
    document.getElementsByClassName('modal fade bd-example-modal-lg')[0].parentElement.style.backgroundColor = 'rgba(0, 0, 0, 0)';
    jQuery('.modal-backdrop').remove()
  }


  visualizzaInformativa() {
    let filex;
    let promise = new Promise<boolean>((resolve) => {
        const base64str = this.informativaDatiPersonali;
        const binary = atob(base64str.replace(/\s/g, ''));
        const len = binary.length;
        const buffer = new ArrayBuffer(len);
        const view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
        }
        filex = view;

        resolve(true);
      }
    );
    promise.then(value => {
      if (value) {
        var file = new Blob([filex], { type: "application/pdf" });
        var fileURL = window.URL.createObjectURL(file);
        window.open(fileURL);
      }
    });
  }

  onSubmitSpid() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    } else {
      let user = new DTOUser();
      let pass;
      this.modalRef2.hide()
      this.modalRef1 = this.modalService.show(this.templeteLoading, {
        backdrop: 'static',
        ignoreBackdropClick: true
      });
      user.cellulare = this.registerForm.get('cellulare').value;
      user.codiceFiscale = this.registerForm.get('codicefiscale').value.toLowerCase();
      user.cognome = this.registerForm.get('cognome').value.toLowerCase();
      user.nome = this.registerForm.get('nome').value.toLowerCase();
      user.email = this.registerForm.get('email').value.toLowerCase();
      user.informativaPrivacy = true;
      user.newsletter = this.registerForm.get('privacy2').value;
      pass = Math.random().toString(36).slice(-8);
      user.passwordToMail = pass;
      user.password = sha256.update(pass).hex().slice(0, 44);


      this.reqService.saveUserSpid(user).subscribe(risposta => {
        if (risposta.autenticato) {
          this.userl.loggedIn = true;
          this.userl.token = risposta.token;
          this.userl.email = risposta.user.email;
          this.userl.loggedIdProfile = risposta.user.profilo.id;
          this.userl.loggedUser = risposta.user.nome;
          this.userl.loggedUserId = risposta.user.id;
          this.userl["spid"] = true;
          this.cookieService.set('usL', JSON.stringify(this.userl), 1);
          (async () => {
            await new Promise(resolve => setTimeout(resolve, 2000));
            this.modalRef1.hide()
            this.routerAuth.navigate(['/']);
          })();

        }
        else{
          this.modalRef1.hide()
          this.modalRef3 = this.modalService.show(this.templateRisultato, {
            backdrop: 'static',
            ignoreBackdropClick: true
          });

        }



      });

    }


  }


  richiediAuth(){
    if(this.spidLogin)
    return
    this.modalRef2 = this.modalService.show(this.templateRegistrazioneAuth, {
      backdrop: 'static',
      ignoreBackdropClick: true
    });

  }

  onSubmitAuth(){
      this.submitted = true;
      if (this.registerFormAuth.invalid) {
        return;
      } else {
        let user = new DTOUser();
        let pass;
        this.modalRef2.hide()
        this.modalRef1 = this.modalService.show(this.templeteLoading, {
          backdrop: 'static',
          ignoreBackdropClick: true
        });
        user.cellulare = this.registerFormAuth.get('cellulare').value;
        user.codiceFiscale = this.registerFormAuth.get('codicefiscale').value.toLowerCase();
        user.cognome = this.registerFormAuth.get('cognome').value.toLowerCase();
        user.nome = this.registerFormAuth.get('nome').value.toLowerCase();
        user.email = this.registerFormAuth.get('email').value.toLowerCase();
        user.informativaPrivacy = true;
        user.newsletter = this.registerFormAuth.get('privacy2').value;
        let file = new DTOFile();
        file.fileContent = this.allegato.fileContent
        file.fileExt = this.allegato.fileExt
        file.fileTitolo = this.allegato.titoloAllegato
        file.messaggio = this.registerFormAuth.get('messaggio').value.toLowerCase();
        let obj = {user:user,file:file}
        this.reqService.richiediCredenziali(obj).subscribe(risposta => {
          if(risposta!=null){
            this.finalAuth = risposta
            this.modalRef1.hide()
            this.modalRef2= this.modalService.show(this.templateRisultatoAuth, {
              backdrop: 'static',
              ignoreBackdropClick: true
            });
          }
        })
      }





  }
  fileInputed(files: FileList) {
    if (files == null || files.item(0) == null || files.item(0).name == null)
      return null
    let typeArray = this.cloneDeep(files.item(0).name.split("."))
    if (typeArray.length > 2 && typeArray[typeArray.length - 1] != "p7m") {
      window.alert("i file con più di un punto nel nome non sono permessi!")
      return null
    }
    if (typeArray.length < 2) {
      window.alert("i file devono avere un'estenzione valida!")
      return null
    }
    let type: string = typeArray[typeArray.length - 1]
    let reader = new FileReader();


    if(typeArray[0].length>100){
      window.alert(REGOLEALLEGATI.MSGLUN_DEN_ALLEGATI)
      return
    }
    if(typeArray[0].split("-").length >1 && typeArray[0].split("_").length > 1 ){
      window.alert(REGOLEALLEGATI.MSGUNDERSCORE)
      return
    }
    if((files.item(0).size/(1000000))>REGOLEALLEGATI.D_MAX_ALLEGATI){
      window.alert(REGOLEALLEGATI.MSGDIM_MAX_ALLEGATI)
      return
    }
    let comp = false
    let formatiCompatibili = UtilityForDomande.getAllegati().formatiCompatibili
    let compatibili = UtilityForDomande.getAllegati().formatiDownload

    formatiCompatibili.forEach(element => {
      element.formato.forEach(element2 => {
        if (element2 == type.toLocaleLowerCase())
          type = _.cloneDeep(element.tipo)
      });
    });
    compatibili.forEach(element => {
      if (element.ext == type.toLocaleLowerCase()) {
        comp = true
      }

    });
    if(!comp){
      window.alert("Non sono consentiti upload di file con estenzione " + type.toUpperCase())
      return
    }
    reader.readAsDataURL(files.item(0));
    reader.onload = () => {
      let fileForamtted = {
        titoloAllegato: typeArray.length > 2 ? typeArray[0] + ".pdf" : typeArray[0],
        fileExt: type.toLocaleLowerCase(),
        fileContent: this.cloneDeep(("" + reader.result).split(",")[1]),

      }
      this.allegato = fileForamtted

    }


  }

  cloneDeep(toClone: any): any {
    if (toClone == null)
      return toClone
    return JSON.parse(JSON.stringify(toClone))
  }

  isThisFileDangerus(type: string): any {
    let esito: boolean = false
    dangerusTypes.forEach(dangerusType => {
      if ((type.toLowerCase().trim()) == (dangerusType.toLowerCase()).trim()) esito = true
    })
    return esito
  }
  // Funzione per verificare se una stringa è un JSON valido
private isValidJsonString(str: string): boolean {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}
}
