import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { appTitle } from '../environments/custom/environment.locale';
import { alertEnviroment, titleEnviroment } from '../environments/custom/environment.locale'

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private titleService: Title) { }



  ngOnInit() {

  

    this.titleService.setTitle(titleEnviroment + " " + alertEnviroment)

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
