import {Injectable} from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {
  constructor() {
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet = ExcelService.autofitColumns(json);
    const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    ExcelService.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private static saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    let today = new Date();
    FileSaver.saveAs(data, fileName + today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear() + EXCEL_EXTENSION);
  }

  private static autofitColumns(json) {
    let objectMaxLength = [];
    for (let i = 0; i < json.length; i++) {
      let value = <any>Object.values(json[i]);
      for (let j = 0; j < value.length; j++) {
        if (typeof value[j] == 'number') {
          objectMaxLength[j] = 10;
        } else {
          objectMaxLength[j] =
            objectMaxLength[j] >= value[j].length
              ? objectMaxLength[j]
              : value[j].length;
        }
      }
    }

    var wscols = [
      {width: objectMaxLength[0]},  // first column
      {width: objectMaxLength[1]}, // second column
      {width: objectMaxLength[2]}, //...
      {width: objectMaxLength[3]},
      {width: objectMaxLength[4]},
      {width: objectMaxLength[5]},
      {width: objectMaxLength[6]},
      {width: objectMaxLength[7]},
      {width: objectMaxLength[8]},
      {width: objectMaxLength[9]}
    ];

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    worksheet['!cols'] = wscols;

    return worksheet;
  }

  // public exportAsExcelFile2(json: any[], excelFileName: string, headersArray: any[]): void {
  //   worksheet.columns = [
  //     {header: 'Id', key: 'id', width: 10},
  //     {header: 'Name', key: 'name', width: 32},
  //     {header: 'D.O.B.', key: 'dob', width: 15,}
  //   ];
  //
  //   worksheet.addRow({id: 1, name: 'John Doe', dob: new Date(1970, 1, 1)});
  //   worksheet.addRow({id: 2, name: 'Jane Doe', dob: new Date(1965, 1, 7)});
  //
  //  //Excel Title, Header, Data
  //   const header = headersArray;
  //   const data = json;
  //   //Create workbook and worksheet
  //   let workbook = new Workbook();
  //   let worksheet = workbook.addWorksheet(excelFileName);
  //   //Add Header Row
  //   let headerRow = worksheet.addRow(header);
  //   // Cell Style : Fill and Border
  //   headerRow.eachCell((cell, number) => {
  //     cell.fill = {
  //       type: 'pattern',
  //       pattern: 'solid',
  //       fgColor: {argb: 'FFFFFF00'},
  //       bgColor: {argb: 'FF0000FF'}
  //     };
  //     cell.border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}};
  //   });
  //   // Add Data and Conditional Formatting
  //   data.forEach((element) => {
  //     let eachRow = [];
  //     headersArray.forEach((headers) => {
  //       eachRow.push(element[headers]);
  //     });
  //     if (element.isDeleted === 'Y') {
  //       let deletedRow = worksheet.addRow(eachRow);
  //       deletedRow.eachCell((cell, number) => {
  //         cell.font = {name: 'Calibri', family: 4, size: 11, bold: false, strike: true};
  //       });
  //     } else {
  //       worksheet.addRow(eachRow);
  //     }
  //   });
  //   worksheet.getColumn(3).width = 15;
  //   worksheet.getColumn(4).width = 20;
  //   worksheet.getColumn(5).width = 30;
  //   worksheet.getColumn(6).width = 30;
  //   worksheet.getColumn(7).width = 10;
  //   worksheet.addRow([]);
  //   //Generate Excel File with given name
  //   workbook.xlsx.writeBuffer().then((data) => {
  //     let blob = new Blob([data], {type: EXCEL_TYPE});
  //     FileSaver.saveAs(blob, excelFileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  //   });
  // }
}
