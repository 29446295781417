import {Injectable} from '@angular/core';
import {Printer} from '../printerInterface';

@Injectable({
  providedIn: 'root'
})
export class LorenzoPrinterService extends Printer {

  constructor() {
    super();
  }

  getOwner(): string {
    return 'lorenzo';
  }

}
