		/*
const domande = {
    campList: [{ prop: "titolo", propFormatted: "Titolo " }, { prop: "richiedente", propFormatted: "Richiedente" }, { prop: "stato", propFormatted: "Stato" }],
}
*/
/*
const bandi = {
    campList: [{ prop: "titolo", propFormatted: "Titolo " },{ prop: "datainizivalidita", propFormatted: "data Inizio Validita" },
    { prop: "datafinevalidita", propFormatted: "data Fine Validita" },{ prop: "richiedente", propFormatted: "Richiedente" }, { prop: "stato", propFormatted: "Stato" }],
}
*/

const domande = {
    campList: [
        { prop: "identificativo", propFormatted: "Codice" },
        { prop: "dataCreazione", propFormatted: "Data Creazione" },
        { prop: "richiedente", propFormatted: "Richiedente" },
        { prop: "idCodiceTipologiaSoggetto", propFormatted: "Tipologia richiedente" },
        {prop:"stato", propFormatted: "Stato"}

    ]
}

//camp per istruttori
const domandeIstruttore = {
    campList: [
        { prop: "codiceBando", propFormatted: "Bando" },
        { prop: "identificativo", propFormatted: "Cod" },
        { prop: "dataFinalizzazione", propFormatted: "Data fin" },
        {prop: "dataChiusuraBando", propFormatted: "Chiusura b." },
        {prop: "tipoPresentazioneDomanda", propFormatted: "Pres. Dom." },
        { prop: "utente", propFormatted: "Comp." },
        { prop: "istruttoriList", propFormatted: "Istruttore" },
        {prop:"fase", propFormatted: "Fase"},
        {prop:"stato", propFormatted: "Stato"},
        {prop:"esito", propFormatted: "Esito"},


    ]
}

const domandeIstruttorePm = {
    campList: [
        { prop: "codiceBando", propFormatted: "Bando" },
        { prop: "identificativo", propFormatted: "Cod" },
        { prop: "capofila", propFormatted: "Capofila" },
        { prop: "faseErog", propFormatted: "Fase di Erogazione" },
        {prop: "dataFinRichiesta", propFormatted: "Data Fin. Rich." },
        {prop: "tempo", propFormatted: "Tempo (giorni)" },
        { prop: "utente", propFormatted: "Comp." },
        { prop: "istruttoriList", propFormatted: "Istruttore" },
        {prop:"fase", propFormatted: "Fase"},
        {prop:"stato", propFormatted: "Stato"},



    ]
}


const bandi = {
    campList: [
        { prop: "codice", propFormatted: "Codice" },
        { prop: "denominazione", propFormatted: "Denominazione" },
        { prop: "finalita", propFormatted: "Sottotitolo" },
        { prop: "annualita", propFormatted: "Annualità" },
        { prop: "dataInizioPresentazione", propFormatted: "Apertura" },
        { prop: "dataInizioValidita", propFormatted: "Data Inizio Finalizzazione" },
        { prop: "dataFineValidita", propFormatted: "Chiusura" },


        { prop: "richiedente", propFormatted: "Richiedente" }
        // { prop: "stato", propFormatted: "Stato" }
    ],
}

const allegati = {
    campList: [{ prop: "id", propFormatted: "Id" }, { prop: "titolo", propFormatted: "Titolo " }, { prop: "ambito", propFormatted: "Ambito" }, { prop: "obbligatorio", propFormatted: "Obbligatorio" }],
    formatiCompatibili: [
        { tipo: "jpg", formato: ["jpeg", "jpe", "jif", "jfif", "jfi"] },
        { tipo: "tif", formato: ["tiff"] },
        { tipo: "xlsx", formato: ["xls"] },
    ],
    formatiDownload:
        [
            { ext: "pdf", stringa: "data:application/pdf;base64,", mime: "application/pdf" },
            { ext: "p7m", stringa: "data:application/p7m;base64,", mime: "application/pkcs7-mime" },
            { ext: "png", stringa: "data:application/png;base64,", mime: "image/png" },
            { ext: "jpg", stringa: "data:application/jpg;base64,", mime: "image/jpeg" },
            { ext: "gif", stringa: "data:application/gif;base64,", mime: "image/gif" },
            { ext: "tif", stringa: "data:application/tif;base64,", mime: "image/tiff" },
            { ext: "xlsx", stringa: "data:application/xlsx;base64,", mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
            { ext: "doc", stringa: "data:application/doc;base64,", mime: "application/msword" },
            { ext: "docx", stringa: "data:application/docx;base64,", mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" },
            { ext: "txt", stringa: "data:application/txt;base64,", mime: "text/plain" },
            { ext: "csv", stringa: "data:application/csv;base64,", mime: "text/csv" },
            { ext: "pptx", stringa: "data:application/pptx;base64,", mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation" },
            { ext: "xml", stringa: "data:application/xml;base64,", mime: "application/xml" },
        ],
    extList: [
        { id: 1, exte: "pdf" },
        { id: 2, exte: "p7m" },
        { id: 3, exte: "png" },
        { id: 4, exte: "jpg" },
        { id: 5, exte: "gif" },
        { id: 6, exte: "tif" },
        { id: 7, exte: "xlsx" }]
}



const dangerousTypesOfFiles = ["exe", "pif", "application", "gadget",
    "msi", "msp", "msp", "com", "src", "hta",
    "cpl", "msc", "bat", "cmd", "vb", "vbs",
    "vbe", "js", "jse", "ws", "wsf", "wsc",
    "wsh", "ps1", "ps1xml", "ps2", "ps2xml",
    "psc1", "psc2", "msh", "msh1", "msh2", "mshxml",
    "msh1xml", "msh2xml", "scf", "lnk", "inf", "reg", "js",
    "ddl", "zip", "rar"]


const graduatoria = {
    campList: [
    { prop: "codiceBando", propFormatted: "Codice Bando" },
    {prop: "denominazione", propFormatted: "Denominazione bando" },
    { prop: "dataChiusuraBando", propFormatted: "Data Apertura/Chiusura" },
    {prop:"stato", propFormatted:"Stato"},





]
}

const graduatoriaDomande = {
    campList: [
    { prop: "codiceDomanda", propFormatted: "Cod Dom" },
    {prop: "contributoFinale", propFormatted: "Contributo" },
    { prop: "punteggio", propFormatted: "Punteggio" },
    {prop:"riserva", propFormatted:"Riserva"},
    {prop:"finanziabile", propFormatted:"Finanziabile"},
    {prop:"classifica", propFormatted:"Posizione"},
    {prop:"finanziato", propFormatted:""}



],
campListNonAmmesse:[
    { prop: "codiceDomanda", propFormatted: "Cod Dom" },
    {prop: "contributo", propFormatted: "Contributo" },
    { prop: "punteggio", propFormatted: "Punteggio" },
    {prop:"riserva", propFormatted:"Riserva"},
    {prop:"ammesso",propFormatted:""}

]
}

const campionamento = {
    campList: [
    { prop: "codiceBando", propFormatted: "ID Bando" },
    {prop: "identificativo", propFormatted: "ID Domanda" },
    { prop: "richiedente", propFormatted: "Richiedente" },
    {prop:"compilatore", propFormatted:"Compilatore"},
    {prop:"dataFinalizzazione", propFormatted:"Data Finalizzazione"},
    {prop:"tipoControlloCampionamento1", propFormatted:"Campionamento DURC"},

    {prop:"durc", propFormatted:"DURC"},





]

}

const listaDomande = {
    campList: [
    {prop: "codiceBando", propFormatted: "ID Bando" },
    {prop: "identificativo", propFormatted: "ID Domanda" },
    {prop: "richiedente", propFormatted: "Richiedente" },
    {prop:"compilatore", propFormatted:"Compilatore"},
    {prop:"dataFinalizzazione", propFormatted:"Data Finalizzazione"},
    {prop:"stato", propFormatted:"Stato"},
    {prop:"tipoControlloCampionamento1", propFormatted:"Campionamento DURC"},
    {prop:"durc", propFormatted:"DURC"},
]

}

const modaleCampionamento = {
    campList: [
    { prop: "infoAnag", propFormatted: "Richiedente" },
    { prop: "codiceFiscaleRichiesta", propFormatted: "Codice Fiscale" },
    {prop: "esente", propFormatted: "Esente" },
    { prop: "protocollo", propFormatted: "Numero Protocollo" },
    {prop:"esito", propFormatted:"Stato Inail"},
    {prop:"esitoInps", propFormatted:"Stato Inps"},
    {prop:"esitoCE", propFormatted:"Stato CE"},
    {prop:"dataScadenza", propFormatted:"Data Scadenza"},
    {prop:"allega", propFormatted:"Allega file"},
    {prop:"note", propFormatted:"Note"},
    {prop:"code", propFormatted:"Esito"},
    {prop:"dataSaveManuale", propFormatted:"Data Richiesta"},
    //{prop:"dataRichiesta", propFormatted:"Data ricezione esito"},





]
}

const codiciIstruttoria = {
    campList: [
    { num: 1, msgString: "Regolare" },
    { num: 0, msgString: "In Elaborazione" },
    { num: 2, msgString: "Istruttoria in corso" },
    { num: 3, msgString: "Non regolare" },
    { num: -1, msgString: "Richiesta non presente" },
    { num: 4, msgString: "Da non elaborare" },
    { num: 5, msgString: "Annullata" },
    { num: 6, msgString: "Non disponibile" },

]
}

export const REGOLEALLEGATI = {
   D_MAX_ALLEGATI: 8,
   MSGDIM_MAX_ALLEGATI:"La dimensione del file non può essere superiore agli 8 MB",
   MSGLUN_DEN_ALLEGATI:"La denominazione del file non può essere superiore ai 100 caratteri",
   MSGUNDERSCORE:"Non è permessa la presenza contemporanea dei caratteri “_” e “-“ nella denominazione del file" ,
   MSGDUEPUNTI:"Non è permessa la presenza contemporanea di più di un punto nella denominazione del file",
   DIMMAX:"Attenzione, la dimensione totale degli allegati supera 10MB.\nSi prega di rispettare questo limite prima di procedere al salvataggio."

};



export class UtilityForDomande {

    static getAllDomande(): any {
        return domande
    }
    static getAllDomandeIstruttore(): any {
        return domandeIstruttore
    }
    static getAllBandi(): any {
        return bandi
    }

    static getAllegati(): any {
        return allegati
    }


    static getMetaDatiAllegatiFormExt(est: string): any {
        allegati.formatiCompatibili.forEach(element => {
            element.formato.forEach(element2 => {
              if (element2 == est)
              est = (element.tipo)
            });
          });

        let trovato
        allegati.formatiDownload.forEach(file => {

            if (file.ext == est.toLowerCase()) {
                trovato = file
            }
        });

        return trovato

    }

    static getDangerusFileTypes() {
        return dangerousTypesOfFiles;
    }

    static isThisFileDangerus(type: string): boolean {
      let esito = false;

      dangerousTypesOfFiles.forEach(dangerusType => {
        if ((type.toLowerCase().trim()) === (dangerusType.toLowerCase()).trim()) {
          esito = true;
        }
      });
      return esito;
    }

    static getAllGraduatoria(){
        return graduatoria;
    }

    static getAllGraduatoriaDomande(){
        return graduatoriaDomande;
    }

    static getAllDomandeIstruttorePM(): any {
        return domandeIstruttorePm
    }

    static getAllDomandeCampionamento(): any {
        return campionamento
    }
    static getAllModaleCampionamento(): any {
        return modaleCampionamento
    }
    static getAllCodiciIstruttoria(): any {
        return codiciIstruttoria
    }
    static getAllDomandeListaDomande(): any {
        return listaDomande
    }
}
