import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-istr-auth',
  templateUrl: './istr-auth.component.html',
  styleUrls: ['./istr-auth.component.scss']
})
export class IstrAuthComponent implements OnInit {

  constructor(private routerAuth: Router) { }

  ngOnInit() {
  }
  torna(){
    this.routerAuth.navigate(["/login"])
  }

}
