import {Component, OnInit, TemplateRef, Input, Output,EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DTOUser} from '../../Util/DTO/DTOUser';
import {BsLocaleService, BsModalRef, BsModalService} from 'ngx-bootstrap';
import {RequestService} from '../../services/request.service';
import {sha256} from 'js-sha256';
import {UtilityForDomande} from '../gestioneDomande/utility';


declare var jQuery: any;

@Component({
  selector: 'app-registrazione',
  templateUrl: './registrazione.component.html',
  styleUrls: ['./registrazione.component.scss'],
  providers: [FormBuilder]
})
export class RegistrazioneComponent implements OnInit {
  @Input("UserSpid") user : any
  @Output('syncCloseModal') syncCloseModal: EventEmitter<any> = new EventEmitter();
  registerForm: FormGroup;
  submitted = false;
  date = new Date(Date.now());
  esito: boolean = true;
  servermsg: string = '';
  modalRef: BsModalRef;
  rispSalvataggio: any;
  informativaDatiPersonali: string;
  checkMail = false;

  constructor(private router: Router, private formBuilder: FormBuilder, private _localeService: BsLocaleService
    , private reqService: RequestService, private modalService: BsModalService) {
    this._localeService.use('it');
  }

  ngOnInit() {
    this.reqService.downloadInformativaDatiPersonali().subscribe(
      data => {
        this.informativaDatiPersonali = data.datiPersonalibase64.toString();
      }
    );
    console.log(this.user)
    this.registerForm = this.formBuilder.group({
      nome: [this.user.nome, Validators.required],
      cognome: [this.user.cognome, Validators.required],
      email: [this.user.email, [Validators.required, Validators.email]],
      email_check: [this.user.email, [Validators.required, Validators.email]],
      privacy: [false, Validators.requiredTrue],
      privacy2: [false],
      codicefiscale: [this.user.codiceFiscale, [Validators.required, Validators.minLength(16), Validators.maxLength(16)]],
      sesso: [''],
      cellulare: [this.user.cellulare, Validators.pattern('^[0-9]*$')],
      datanascita: [''],
      luogonascita: ['']
    });
    /*this.registerForm.patchValue({
      sesso:"maschio"
    });*/
  }

  get f() {
    return this.registerForm.controls;
  }

  onSubmit(template: TemplateRef<any>) {
    this.submitted = true;
    if (this.registerForm.invalid || this.checkMail) {
      return;
    } else {
      let user = new DTOUser();
      let pass;
      user.cellulare = this.registerForm.get('cellulare').value;
      user.codiceFiscale = this.registerForm.get('codicefiscale').value.toLowerCase();
      user.cognome = this.registerForm.get('cognome').value.toLowerCase();
      user.nome = this.registerForm.get('nome').value.toLowerCase();
      /*user.luogoNascita=this.registerForm.get('luogonascita').value.toLowerCase()
      user.dataNascita=this.customFormatDateForBE(this.registerForm.get('datanascita').value)*/
      user.email = this.registerForm.get('email').value.toLowerCase();
      user.informativaPrivacy = true;
      user.newsletter = this.registerForm.get('privacy2').value;
      pass = Math.random().toString(36).slice(-8);
      user.passwordToMail = pass;
      user.password = sha256.update(pass).hex().slice(0, 44);

      /*this.registerForm.get('sesso').value=='maschio'?
      user.sesso="m":user.sesso="f"*/
      
      
      this.reqService.saveuserRequest(user).subscribe(risposta => {
        this.esito = risposta.esito;
        this.servermsg = risposta.messaggio;
        this.rispSalvataggio = risposta;

      });

    }

    //this.router.navigate(['/login'])
    this.syncCloseModal.emit({
      chiudi: true

    });
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
      ignoreBackdropClick: true
    });

  }

  chiudi() {
    
   
    this.syncCloseModal.emit({
      chiudi: true

    });

    
    this.router.navigate(['/login']);
  }

  checkCF() {
  }

  customFormatDateForBE(unFormattedDate: string) {
    let risultato: string;
    if (unFormattedDate[2] == '-') {
      risultato = unFormattedDate[6] + unFormattedDate[7] + unFormattedDate[8] + unFormattedDate[9];
      risultato += unFormattedDate[5] + unFormattedDate[3] + unFormattedDate[4];
      risultato += unFormattedDate[2] + unFormattedDate[0] + unFormattedDate[1];
    } else {
      risultato = unFormattedDate;
    }

    return risultato;
  }

  chiudims(template: TemplateRef<any>) {
    this.modalRef.hide();
    this.router.navigate(['/login']);
  }

  visualizzaInformativa() {
    let filex;
    let promise = new Promise<boolean>((resolve) => {
        const base64str = this.informativaDatiPersonali;
        const binary = atob(base64str.replace(/\s/g, ''));
        const len = binary.length;
        const buffer = new ArrayBuffer(len);
        const view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
        }
        filex = view;

        resolve(true);
      }
    );
    promise.then(value => {
      if (value) {
        var file = new Blob([filex], { type: "application/pdf" });
        var fileURL = window.URL.createObjectURL(file);
        window.open(fileURL);
      }
    });
  }

  ckeckM(){
    if(this.registerForm.get('email').value.toLowerCase() == this.registerForm.get('email_check').value.toLowerCase())
      this.checkMail = false  
    else
      this.checkMail = true
      console.log(this.checkMail)
  }
}
