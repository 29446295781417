
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';



//import { ErrorDialogService } from '../../../../node_modules/error-dialog/errordialog.service';
import {

    HttpErrorResponse
} from '@angular/common/http';
import { DTOgeneralReqEncapsulator } from '../../Util/DTO/DtogeneralReqEncapsulator';
import { CookieService } from 'ngx-cookie-service';
import { TestCaricoService } from '../testDiCarico/testDiCaricoService/test-carico.service';



@Injectable()
export class InterTest implements HttpInterceptor {

    constructor(

        private cookieService: CookieService,
        private router: Router,
        private recordingService: TestCaricoService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        let currentToken: string = ""
        if (this.cookieService.get('usL') != "") {
            if ((JSON.parse(this.cookieService.get("usL")).token) != null && (JSON.parse(this.cookieService.get("usL")).token.length) > 3)
                currentToken = (JSON.parse(this.cookieService.get("usL")).token)
            else
                if ((JSON.parse(this.cookieService.get("usL")).token) != null && (JSON.parse(this.cookieService.get("usL")).token.length) > 3)
                    currentToken = (JSON.parse(this.cookieService.get("usL")).token)

        }



        /*
                //console.log("Interc: " +
                    `token ` + currentToken
                )*/

        //Access-Control-Allow-Origin


        //const clonedRequest = req.clone({ headers: req.headers.set('Authorization', 'token ' + localStorage.getItem("token")) });
        let clonedRequest: HttpRequest<any>


        clonedRequest = req.clone({

            body: (currentToken != null && currentToken != "") ?
                new DTOgeneralReqEncapsulator((JSON.parse(this.cookieService.get("usL")).loggedIdProfile), JSON.stringify(req.body),
                    currentToken, +JSON.parse(this.cookieService.get('usL')).loggedUserId) :
                new DTOgeneralReqEncapsulator(0, JSON.stringify(req.body), "", null),
            setHeaders: {

                Authorization: currentToken,
                Accept: "application/json"
            },

            headers: new HttpHeaders().append(
                "Authorization",
                currentToken



            ).append('Content-Type', 'application/json')
                .append("Accept", "application/json")
        });




        //  console.log("INTERCETTATA CHIAMATA ")
        //  console.log(clonedRequest.body)
        //console.log("cloned http: " + JSON.stringify(clonedRequest.headers))
        if (this.recordingService.isRecording())
            this.recordingService.recordOne(clonedRequest)
        return next.handle(clonedRequest).pipe(
            catchError((error: HttpErrorResponse) => {
                ////console.log("INTERCETTATA RISPOSTA " + JSON.stringify(clonedRequest.body))

                let data = {
                    //  reason: error && error.error.reason ? error.error.reason : '',
                    status: error.status
                }
                ////console.log("ERRORE CHIAMATA AL BE: -" + data.status)

                if (data.status == 401 || data.status == 403) {
                    this.router.navigate(['login'])
                    this.cookieService.delete('usL')
                }
                return throwError(error);
            }));


    }





}