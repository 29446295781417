import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { HttpRequest } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class TestCaricoService {

  t1: number
  t2: number
  speedMod: number = 1
  private recording: boolean = false


  private jsonCallsList: any[] = []
  constructor() { }

  isRecording() {
    return this.recording;
  }


  startAndStopRecording(mod: boolean, speedMod) {
    this.recording = mod
    this.t1 = 0
    this.t2 = 0
    this.jsonCallsList = []
    this.speedMod = speedMod
  }



  recordOne(record: HttpRequest<any>) {
    let urlToMod: string = record.url
    //https://gecoplus.lazioinnova.it/service/

    if (urlToMod != null) {
      try {
        urlToMod.replace(/gecoplus.lazioinnova.it/g, "gecoppbeweb-service:8080")
        urlToMod.replace(/https/g, "http​")
      } catch{ "errore nel replace prod" }

      try {
        urlToMod.replace(/10.166.31.195:8086/g, "http://gecoppwebbe:8080/​")
      } catch{ console.log("errore nel replace sviluppo") }




    }

    record = _.cloneDeep(record.clone({ url: urlToMod }))


    this.t1 = performance.now()

    let recordToRec = {
      time: +((this.t1 - this.t2) * this.speedMod).toFixed(0),
      call: record
    }


    this.jsonCallsList.push(_.cloneDeep(recordToRec))

    console.log("record 1")
    console.log(recordToRec)


    this.t2 = performance.now()
  }

  getJsonReced() {
    return this.jsonCallsList
  }

  resetRec() {
    this.t2 = 0
    this.t1 = 0
    this.jsonCallsList = []
    this.recording = false
  }

}
