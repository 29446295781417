export class DTOFile {
    fileContent: string
    fileTitolo: string
    fileExt: string
    messaggio: string

    constructor() {
        this.fileContent = ""
        this.fileTitolo = ""
        this.fileExt = ""
        this.messaggio = ""
    }
}